import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import logoWeMoveWhite from "../../assets/logo/wemove-white.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  attachUserWithMoveRequest,
  createCheckoutSession,
  getMoveRequestById,
  updateMoveRequestById,
} from "../../redux/actions/move-request";
import { unwrapResult } from "@reduxjs/toolkit";
import theme from "../../theme/theme";
import { loadStripe } from "@stripe/stripe-js";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReactGA from 'react-ga4';
import TurboTenantLogo from "../../assets/logo/turbotenant_logo.png"
import ContactSupport from "../../components/contactSupport";
import MoveBeesLogo from "../../assets/logo/MoveBees-Logo-VR-Yellow.jpg"
import KruppLogo from "../../assets/logo/Krupp.png"
import SummitMovingLogo from "../../assets/logo/SummitMoving.png"
// const stripe = new Stripe('pk_test_51O80iGCLryiG8vjCX2ZNIZRuDn7WrC2sKSQiLhYc0o3LgfhQCaJhcTNR2edeGCodvfUwZNg3Y98Z7EVsvNiHcgnx00ymdPPtnC', {
//   apiVersion: '2023-10-16',
// });
const strikeKey = process.env.REACT_APP_STRIPE_KEY || ""
const notSeelectedButtonStyle = {
  width: 130,
  height: 40,
  color: "#5859DF",
  backgroundColor: "#FFFFFF !important",
  fontSize: 12,
  fontWeight: 550,
  border: "1px solid #6552FF",
};

const selectedButtonStyle = {
  width: 150,
  height: 40,
  color: "#FDFCFD",
  backgroundColor: "#46A577 !important",
  fontSize: 12,
  fontWeight: 550,
  // border: "1px solid #6552FF",
};

const MoveSummary = () => {

  const [url, setUrl] = useState(process.env.REACT_APP_URL || "")

  if(url==='https://stagingapi.wemoveai.com/'){
    ReactGA.send({
      hitType: 'pageview',
      page:"/move-summary/:moveRequestId",
      title:"MoveSummary Staging"
    })
  }  
   
  if(url==='https://productionapi.wemoveai.com/'){
    ReactGA.send({
      hitType: 'pageview',
      page:"/move-summary/:moveRequestId",
      title:"MoveSummary Production"
    })
  }  

  if(url==='https://turbotenantapi.wemoveai.com/'){
    ReactGA.send({
      hitType: 'pageview',
      page:"/move-summary/:moveRequestId",
      title:"MoveSummary Turbotenant"
    })
  } 

  if(url==='https://soldapi.wemoveai.com/'){
    ReactGA.send({
      hitType: 'pageview',
      page:"/move-summary/:moveRequestId",
      title:"MoveSummary Sold"
    })
  }  

  const currentTenant = process.env.REACT_APP_TENANT || "";
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();

  const { moveRequestId } = useParams();
  const [thisMoveRequestId, setThisMoveRequestId] = useState(null);
  const [moveRequest, setMoveRequest] = useState<any>(null);

  const [isPageLoading, setIsPageLoading] = useState(false);

  const [totalCubicFeet, setTotalCubicFeet] = useState(0.0);
  const [totalMovePrice, setTotalMovePrice] = useState(0.0);
  const [weMoveCommision, setWeMoveCommission] = useState(0.0);
  const [pickupCharges, setPickupCharges] = useState(0);
  const [priceOfItemsOnly, setPriceOfItemsOnly] = useState(0.0);
  const [moveDistance, setMoveDistance] = useState<any>(0.0)
  const getDate = () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${month}/${date}/${year}`;
  };

  const fetchMoveRequestById = (moveRequestId: any) => {
    dispatch<any>(getMoveRequestById(moveRequestId))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        setMoveRequest(thisMoveRequest);
        console.log(thisMoveRequest,'--THIS')
        setTotalCubicFeet(Math.ceil(Number(thisMoveRequest.total_cubic_feet)));
        if(thisMoveRequest.move_distance!==null){
          setMoveDistance(Number(thisMoveRequest.move_distance))
        }else{
          setMoveDistance(thisMoveRequest.move_distance)
        }
        setThisMoveRequestId(thisMoveRequest.id);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const checkFromAddress = (moveReq: any) => {
    if (moveReq?.home_address !== null) {
      return moveReq.home_address;
    }

    if (moveReq?.apartment !== null) {
      return moveReq.apartment?.apt_address;
    }

    if (moveReq?.storage !== null) {
      return moveReq.storage?.address;
    }

    if (moveReq?.combo_home_storage !== null) {
      return (
        moveReq.combo_home_storage?.home_address.toString() +
        "/" +
        moveReq.combo_home_storage.storage?.address.toString()
      );
    }

    if (moveReq?.combo_apartment_storage !== null) {
      return (
        moveReq.combo_apartment_storage?.apartment?.apt_address.toString() +
        "/" +
        moveReq.combo_apartment_storage?.storage?.address.toString()
      );
    }
    return "---";
  };

  const formatDate = (date: Date) => {
    const isoString: string = date.toString();
    const datePart: string = isoString.split("T")[0];

    return datePart;
  };

  const chargesForPickupLocation = (move: any) => {
    let finalPickupCharges = 0;

    if (move.apartment !== null) {
      if (move.apartment.is_elevator_available === true) {
        setPickupCharges((charges) => charges + 75);
        finalPickupCharges += 75;
        return finalPickupCharges;
      } else {
        if (
          move.apartment.is_elevator_available === false &&
          move.apartment.floor_no > 2
        ) {
          const additionalFloors = Number(move.apartment.floor_no) - 2;
          const stairCharges = additionalFloors * 75;

          setPickupCharges((charges) => charges + stairCharges);
          finalPickupCharges += stairCharges;
          return finalPickupCharges;
        }
      }
    }

    if (move.storage !== null) {
      if (move.storage.is_elevator_available === true) {
        finalPickupCharges += 75;

        return finalPickupCharges;
      } else {
        if (
          move.storage.is_elevator_available === false &&
          move.storage.are_stairs_present === true
        ) {
          const additionalFloors = Number(move.storage.floor_no) - 2;
          const stairCharges = additionalFloors * 75;

          setPickupCharges((charges) => charges + stairCharges);
          finalPickupCharges += stairCharges;
          return finalPickupCharges;
        }
      }
    }

    if (move.combo_apartment_storage !== null) {
      if (
        move.combo_apartment_storage.apartment.is_elevator_available === true
      ) {
        finalPickupCharges += 75;
      } else {
        if (
          move.combo_apartment_storage.apartment.is_elevator_available ===
            false &&
          move.combo_apartment_storage.apartment.floor_no > 2
        ) {
          const additionalFloors =
            Number(move.combo_apartment_storage.apartment.floor_no) - 2;
          const stairCharges = additionalFloors * 75;

          setPickupCharges((charges) => charges + stairCharges);
          finalPickupCharges += stairCharges;
        }
      }

      if (move.combo_apartment_storage.storage.is_elevator_available === true) {
        finalPickupCharges += 75;
      } else {
        if (
          move.combo_apartment_storage.storage.is_elevator_available ===
            false &&
          move.combo_apartment_storage.storage.are_stairs_present === true
        ) {
          const additionalFloors =
            Number(move.combo_apartment_storage.storage.floor_no) - 2;
          const stairCharges = additionalFloors * 75;

          setPickupCharges((charges) => charges + stairCharges);
          finalPickupCharges += stairCharges;
        }
      }
      return finalPickupCharges;
    }

    if (move.combo_home_storage !== null) {
      if (move.combo_home_storage.storage.is_elevator_available === true) {
        finalPickupCharges += 75;
        return finalPickupCharges;
      } else {
        if (
          move.combo_home_storage.storage.is_elevator_available === false &&
          move.combo_home_storage.storage.are_stairs_present === true
        ) {
          const additionalFloors =
            Number(move.combo_home_storage.storage.floor_no) - 2;
          const stairCharges = additionalFloors * 75;
          setPickupCharges((charges) => charges + stairCharges);
          finalPickupCharges += stairCharges;
          return finalPickupCharges;
        }
      }
    }
    return finalPickupCharges;
  };

  const localMoveFormula = (move:any) => {
    let numberOfMovers=0;
    if(move.total_cubic_feet>=1 && move.total_cubic_feet<=700){
      numberOfMovers = 2
    } else if(move.total_cubic_feet>=701 && move.total_cubic_feet<=1300){
      numberOfMovers = 3
    } else if(move.total_cubic_feet>=1301 && move.total_cubic_feet<=1900){
      numberOfMovers = 4
    } else if(move.total_cubic_feet>=1901 && move.total_cubic_feet<=2500){
      numberOfMovers = 5
    } else if(move.total_cubic_feet>2500){
      numberOfMovers = 6
    }
    let totalCubicFeetOnJob = move.total_cubic_feet*2 //load and unload
    let totalManHours = totalCubicFeetOnJob/104
    let approximateHours = totalManHours/numberOfMovers
    let travelTime = 1;
    if(move.move_distance>50){
      travelTime = 2;
    }
    let mileageFee = 1*move.move_distance;
    const breakTime = 0.5;
    let totalEstimatedTime = approximateHours + travelTime + breakTime
    let costPerHour=0;
    if (numberOfMovers === 2) {
      costPerHour = 180;
    } else if (numberOfMovers === 3) {
      costPerHour = 230;
    } else if (numberOfMovers === 4) {
      costPerHour = 280;
    } else if (numberOfMovers === 5) {
      costPerHour = 350;
    } else if (numberOfMovers === 6) {
      costPerHour = 410;
    }
    let totalMoveCost = (totalEstimatedTime * costPerHour) + mileageFee;
    return totalMoveCost
  }

  const pricingModel = async (moveDistance:any) => {
    if (
      Number(moveDistance) > 0 &&
      Number(moveDistance) <= 50
    ) {
      // const price_per_cubic_feet = 1.53;
      const price = localMoveFormula(moveRequest)
      setPriceOfItemsOnly((total) => total + price);

      setTotalMovePrice((thisPrice) => thisPrice + price);
      if (
        moveRequest.delivery_details.delivery_addresses[0]
          .delivery_location_type !== "Home"
      ) {
        if (
          moveRequest.delivery_details.delivery_addresses[0]
            .is_elevator_accessible
        ) {
          // const price_after_delivery_side_elevator = price + 75;
          setTotalMovePrice((total) => total + 75);
        } else {
          if (
            moveRequest.delivery_details.delivery_addresses[0].stiars_present
          ) {
            if (
              moveRequest.delivery_details.delivery_addresses[0].floor_no > 2
            ) {
              const additionalFloors =
                Number(
                  moveRequest.delivery_details.delivery_addresses[0].floor_no
                ) - 2;
              const flight_fee = additionalFloors * 75;
              setTotalMovePrice((total) => total + flight_fee);
            }
          }
        }
      }

      if (
        moveRequest.delivery_details.packagaing_required === true &&
        moveRequest.delivery_details.packaging !== null
      ) {
        setTotalMovePrice(
          (total) =>
            total +
            Number(moveRequest.delivery_details.packaging.packaging_payment)
        );
      }

      if (
        moveRequest.apartment !== null ||
        moveRequest.storage !== null ||
        moveRequest.combo_apartment_storage !== null ||
        moveRequest.combo_home_storage !== null
      ) {
        const charges = chargesForPickupLocation(moveRequest);
        setTotalMovePrice((total) => total + Number(charges));
      }
      const bulky_fee = await calculateBulkyFee();
      setTotalMovePrice((total)=> total + bulky_fee)
      setWeMoveCommission((commission) => commission + 0.129 * price);
    }

    if (
      Number(moveDistance) > 50 &&
      Number(moveDistance) <= 100
    ) {
      // const price_per_cubic_feet = 2.82;
      const price = localMoveFormula(moveRequest)
      setPriceOfItemsOnly((total) => total + price);
      setTotalMovePrice((thisPrice) => thisPrice + price);
      if (
        moveRequest.delivery_details.delivery_addresses[0]
          .delivery_location_type !== "Home"
      ) {
        if (
          moveRequest.delivery_details.delivery_addresses[0]
            .is_elevator_accessible
        ) {
          // const price_after_delivery_side_elevator = price + 75;
          setTotalMovePrice((total) => total + 75);
        } else {
          if (
            moveRequest.delivery_details.delivery_addresses[0].stiars_present
          ) {
            if (
              moveRequest.delivery_details.delivery_addresses[0].floor_no > 2
            ) {
              const additionalFloors =
                Number(
                  moveRequest.delivery_details.delivery_addresses[0].floor_no
                ) - 2;
              const flight_fee = additionalFloors * 75;
              setTotalMovePrice((total) => total + flight_fee);
            }
          }
        }
      }

      if (
        moveRequest.delivery_details.packagaing_required === true &&
        moveRequest.delivery_details.packaging !== null
      ) {
        setTotalMovePrice(
          (total) =>
            total +
            Number(moveRequest.delivery_details.packaging.packaging_payment)
        );
      }

      if (
        moveRequest.apartment !== null ||
        moveRequest.storage !== null ||
        moveRequest.combo_apartment_storage !== null ||
        moveRequest.combo_home_storage !== null
      ) {
        const charges = chargesForPickupLocation(moveRequest);
        setTotalMovePrice((total) => total + Number(charges));
      }
      const bulky_fee = await calculateBulkyFee();
      setTotalMovePrice((total)=> total + bulky_fee)
      setWeMoveCommission((commission) => commission + 0.129 * price);
    }

    if (
      Number(moveDistance) > 100 &&
      Number(moveDistance) <= 250
    ) {
      const price_per_cubic_feet = 5.12;
      const price = totalCubicFeet * price_per_cubic_feet * 1.1;
      setPriceOfItemsOnly((total) => total + price);
      setTotalMovePrice((thisPrice) => thisPrice + price);
      if (
        moveRequest.delivery_details.delivery_addresses[0]
          .delivery_location_type !== "Home"
      ) {
        if (
          moveRequest.delivery_details.delivery_addresses[0]
            .is_elevator_accessible
        ) {
          // const price_after_delivery_side_elevator = price + 75;
          setTotalMovePrice((total) => total + 75);
        } else {
          if (
            moveRequest.delivery_details.delivery_addresses[0].stiars_present
          ) {
            if (
              moveRequest.delivery_details.delivery_addresses[0].floor_no > 2
            ) {
              const additionalFloors =
                Number(
                  moveRequest.delivery_details.delivery_addresses[0].floor_no
                ) - 2;
              const flight_fee = additionalFloors * 75;
              setTotalMovePrice((total) => total + flight_fee);
            }
          }
        }
      }
      if (
        moveRequest.delivery_details.packagaing_required === true &&
        moveRequest.delivery_details.packaging !== null
      ) {
        setTotalMovePrice(
          (total) =>
            total +
            Number(moveRequest.delivery_details.packaging.packaging_payment)
        );
      }

      if (
        moveRequest.apartment !== null ||
        moveRequest.storage !== null ||
        moveRequest.combo_apartment_storage !== null ||
        moveRequest.combo_home_storage !== null
      ) {
        const charges = chargesForPickupLocation(moveRequest);
        setTotalMovePrice((total) => total + Number(charges));
      }
      const bulky_fee = await calculateBulkyFee();
      setTotalMovePrice((total)=> total + bulky_fee)
      setWeMoveCommission((commission) => commission + 0.279 * price);
    }

    if (
      Number(moveDistance) > 250 &&
      Number(moveDistance) <= 500
    ) {
      const price_per_cubic_feet = 6;
      const price = totalCubicFeet * price_per_cubic_feet * 1.1;
      setPriceOfItemsOnly((total) => total + price);
      setTotalMovePrice((thisPrice) => thisPrice + price);
      if (
        moveRequest.delivery_details.delivery_addresses[0]
          .delivery_location_type !== "Home"
      ) {
        if (
          moveRequest.delivery_details.delivery_addresses[0]
            .is_elevator_accessible
        ) {
          // const price_after_delivery_side_elevator = price + 75;
          setTotalMovePrice((total) => total + 75);
        } else {
          if (
            moveRequest.delivery_details.delivery_addresses[0].stiars_present
          ) {
            if (
              moveRequest.delivery_details.delivery_addresses[0].floor_no > 2
            ) {
              const additionalFloors =
                Number(
                  moveRequest.delivery_details.delivery_addresses[0].floor_no
                ) - 2;
              const flight_fee = additionalFloors * 75;
              setTotalMovePrice((total) => total + flight_fee);
            }
          }
        }
      }
      if (
        moveRequest.delivery_details.packagaing_required === true &&
        moveRequest.delivery_details.packaging !== null
      ) {
        setTotalMovePrice(
          (total) =>
            total +
            Number(moveRequest.delivery_details.packaging.packaging_payment)
        );
      }

      if (
        moveRequest.apartment !== null ||
        moveRequest.storage !== null ||
        moveRequest.combo_apartment_storage !== null ||
        moveRequest.combo_home_storage !== null
      ) {
        const charges = chargesForPickupLocation(moveRequest);
        setTotalMovePrice((total) => total + Number(charges));
      }
      const bulky_fee = await calculateBulkyFee();
      setTotalMovePrice((total)=> total + bulky_fee)
      setWeMoveCommission((commission) => commission + 0.279 * price);
    }

    if (
      Number(moveDistance) > 500 &&
      Number(moveDistance) <= 1500
    ) {
      const price_per_cubic_feet = 6.75;
      const price = totalCubicFeet * price_per_cubic_feet * 1.1;
      setPriceOfItemsOnly((total) => total + price);
      setTotalMovePrice((thisPrice) => thisPrice + price);
      if (
        moveRequest.delivery_details.delivery_addresses[0]
          .delivery_location_type !== "Home"
      ) {
        if (
          moveRequest.delivery_details.delivery_addresses[0]
            .is_elevator_accessible
        ) {
          // const price_after_delivery_side_elevator = price + 75;
          setTotalMovePrice((total) => total + 75);
        } else {
          if (
            moveRequest.delivery_details.delivery_addresses[0].stiars_present
          ) {
            if (
              moveRequest.delivery_details.delivery_addresses[0].floor_no > 2
            ) {
              const additionalFloors =
                Number(
                  moveRequest.delivery_details.delivery_addresses[0].floor_no
                ) - 2;
              const flight_fee = additionalFloors * 75;
              setTotalMovePrice((total) => total + flight_fee);
            }
          }
        }
      }

      if (
        moveRequest.delivery_details.packagaing_required === true &&
        moveRequest.delivery_details.packaging !== null
      ) {
        setTotalMovePrice(
          (total) =>
            total +
            Number(moveRequest.delivery_details.packaging.packaging_payment)
        );
      }
      if (
        moveRequest.apartment !== null ||
        moveRequest.storage !== null ||
        moveRequest.combo_apartment_storage !== null ||
        moveRequest.combo_home_storage !== null
      ) {
        const charges = chargesForPickupLocation(moveRequest);
        setTotalMovePrice((total) => total + Number(charges));
      }
      const bulky_fee = await calculateBulkyFee();
      setTotalMovePrice((total)=> total + bulky_fee)
      setWeMoveCommission((commission) => commission + 0.279 * price);
    }

    if (
      Number(moveDistance) > 1500 &&
      Number(moveDistance) <= 2250
    ) {
      const price_per_cubic_feet = 8;
      const price = totalCubicFeet * price_per_cubic_feet * 1.1;
      setPriceOfItemsOnly((total) => total + price);
      setTotalMovePrice((thisPrice) => thisPrice + price);
      if (
        moveRequest.delivery_details.delivery_addresses[0]
          .delivery_location_type !== "Home"
      ) {
        if (
          moveRequest.delivery_details.delivery_addresses[0]
            .is_elevator_accessible
        ) {
          // const price_after_delivery_side_elevator = price + 75;
          setTotalMovePrice((total) => total + 75);
        } else {
          if (
            moveRequest.delivery_details.delivery_addresses[0].stiars_present
          ) {
            if (
              moveRequest.delivery_details.delivery_addresses[0].floor_no > 2
            ) {
              const additionalFloors =
                Number(
                  moveRequest.delivery_details.delivery_addresses[0].floor_no
                ) - 2;
              const flight_fee = additionalFloors * 75;
              setTotalMovePrice((total) => total + flight_fee);
            }
          }
        }
      }
      if (
        moveRequest.delivery_details.packagaing_required === true &&
        moveRequest.delivery_details.packaging !== null
      ) {
        setTotalMovePrice(
          (total) =>
            total +
            Number(moveRequest.delivery_details.packaging.packaging_payment)
        );
      }
      if (
        moveRequest.apartment !== null ||
        moveRequest.storage !== null ||
        moveRequest.combo_apartment_storage !== null ||
        moveRequest.combo_home_storage !== null
      ) {
        const charges = chargesForPickupLocation(moveRequest);
        setTotalMovePrice((total) => total + Number(charges));
      }
      const bulky_fee = await calculateBulkyFee();
      setTotalMovePrice((total)=> total + bulky_fee)
      setWeMoveCommission((commission) => commission + 0.279 * price);
    }

    if (
      Number(moveDistance) > 2250 &&
      Number(moveDistance) <= 3500
    ) {
      const price_per_cubic_feet = 9;
      const price = totalCubicFeet * price_per_cubic_feet * 1.1;
      setPriceOfItemsOnly((total) => total + price);
      setTotalMovePrice((thisPrice) => thisPrice + price);
      if (
        moveRequest.delivery_details.delivery_addresses[0]
          .delivery_location_type !== "Home"
      ) {
        if (
          moveRequest.delivery_details.delivery_addresses[0]
            .is_elevator_accessible
        ) {
          // const price_after_delivery_side_elevator = price + 75;
          setTotalMovePrice((total) => total + 75);
        } else {
          if (
            moveRequest.delivery_details.delivery_addresses[0].stiars_present
          ) {
            if (
              moveRequest.delivery_details.delivery_addresses[0].floor_no > 2
            ) {
              const additionalFloors =
                Number(
                  moveRequest.delivery_details.delivery_addresses[0].floor_no
                ) - 2;
              const flight_fee = additionalFloors * 75;
              setTotalMovePrice((total) => total + flight_fee);
            }
          }
        }
      }
      if (
        moveRequest.delivery_details.packagaing_required === true &&
        moveRequest.delivery_details.packaging !== null
      ) {
        setTotalMovePrice(
          (total) =>
            total +
            Number(moveRequest.delivery_details.packaging.packaging_payment)
        );
      }

      if (
        moveRequest.apartment !== null ||
        moveRequest.storage !== null ||
        moveRequest.combo_apartment_storage !== null ||
        moveRequest.combo_home_storage !== null
      ) {
        const charges = chargesForPickupLocation(moveRequest);
        setTotalMovePrice((total) => total + Number(charges));
      }
      const bulky_fee = await calculateBulkyFee();
      setTotalMovePrice((total)=> total + bulky_fee)
      setWeMoveCommission((commission) => commission + 0.279 * price);
    }
  };

  const updateThisMoveRequest = async (params: any) => {
    console.log("Params in update function=>>>>", params)
    const stripe = await loadStripe(strikeKey);

    setIsPageLoading(true);
    const valueToSend = Math.ceil(weMoveCommision) * 100;

    const body = {
      price: valueToSend,
      canonical_id: moveRequestId,
    };

    const finalParams = {
      id: thisMoveRequestId,
      ...params,
    };

    dispatch<any>(updateMoveRequestById(finalParams))
      .then(unwrapResult)
      .then((res: any) => {
        setMoveRequest(res);
        setTimeout(() => {
          setIsPageLoading(false);
          navigate(`/contract/${moveRequest.canonical_id}`)
        }, 1300);
        // dispatch<any>(attachUserWithMoveRequest(params2))
        //   .then(unwrapResult)
        //   .then((res:any)=>{
        //     console.log("res=>",res)
        //   })

        // dispatch<any>(createCheckoutSession(body))
        //   .then(unwrapResult)
        //   .then((responseSession: any) => {
        //     stripe?.redirectToCheckout({
        //       sessionId: responseSession.sessionId,
        //     });
        //     setTimeout(() => {
        //       setIsPageLoading(false);
        //     }, 1300);
        //   })
        //   .catch((err: any) => {
        //     console.log(err);
        //     setTimeout(() => {
        //       setIsPageLoading(false);
        //     }, 1300);
        //   });
      })
      .catch((err: any) => {
        console.log(err);
        setTimeout(() => {
          setIsPageLoading(false);
        }, 1300);
      });
  };

  const [showDistanceModal, setShowDistanceModal] = useState(false)
  useEffect(() => {
    if (totalCubicFeet !== 0.0 && totalMovePrice === 0.0 && moveDistance!==0.0 && moveDistance!==null) {
      pricingModel(moveDistance);
    }
    if(moveDistance===null){
      setShowDistanceModal(true)
    }
  }, [totalCubicFeet, moveDistance]);

  useEffect(() => {
    setIsPageLoading(true);
    fetchMoveRequestById(moveRequestId);
    setTimeout(() => {
      setIsPageLoading(false);
    }, 1300);
  }, []);
const [tempDistance, setTempDistance] = useState<any>("")
const handleDistanceChange = (e: any) => {
  let inputValue = e.target.value;
  inputValue = inputValue.replace(/\D/g, "");
  const maxLength = 4;

  if (inputValue.length > maxLength) {
    inputValue = inputValue.substring(0, maxLength);
  }

  setTempDistance(inputValue);
};
const handleDistanceSubmit = () => {
  if(tempDistance>3500){
    alert('Please enter a distance less than 3500 miles.')
  }else{
    setMoveDistance(Number(tempDistance))
    setShowDistanceModal(false)
  }
}
const navigate = useNavigate()

const calculateBulkyFee = async () => {
  let total_bulky_fee = 0;
  moveRequest?.roomDetails.forEach((room: any) => {
    room.room_images?.forEach((image: any) => {
      image.items?.forEach((item: any) => {
        if (item.is_bulky && item.bulky_fee !== 0) {
          total_bulky_fee += item.bulky_fee;
        }
      });
    });
  });
  return total_bulky_fee;
}
const [bulkFee, setBulkFee] = useState(0)
const getBulkFee = async() => {
  const fee =  await calculateBulkyFee()
  setBulkFee(fee)
}
// useEffect(()=>{
//   if(moveRequest!==null){
//     getBulkFee()
//   }
// },[moveRequest])
const confirmMovePayment = async() => {
  const stripe = await loadStripe(strikeKey);

    setIsPageLoading(true);
    const valueToSend = Math.ceil(weMoveCommision) * 100;

    const body = {
      price: valueToSend,
      canonical_id: moveRequestId,
    };
     dispatch<any>(createCheckoutSession(body))
       .then(unwrapResult)
       .then((responseSession: any) => {
        stripe?.redirectToCheckout({
          sessionId: responseSession.sessionId,
          });
          setTimeout(() => {
          setIsPageLoading(false);
          }, 1300);
        })
      .catch((err: any) => {
      console.log(err);
      setTimeout(() => {
        setIsPageLoading(false);
        }, 1300);
     });
}
  return (
    <>
     <Modal
    open={showDistanceModal}
    onClose={()=>{setShowDistanceModal(true)}}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
      <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: '50%',
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 3,
            alignItems:'center',
            justifyContent:'center',
            border: `4px solid ${theme.palette.primary.main}`
          }}
        >
          <Typography fontSize={14} mb={1}>We could not determine the distance between your pickup and delivery locations, please enter an approximate distance.</Typography>
          <Typography fontSize={12} fontWeight={600} mb={2}>Note: This approximation will be used in calculating your quote.</Typography>
          <Stack width={150} spacing={1}>

           <TextField
                    variant="outlined"
                    type="text"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    label="Distance"
                    sx={{ width: 150 }}
                    size="small"
                    required
                    value={tempDistance || ""}
                    onChange={handleDistanceChange}

                    />
                  <Button disabled={tempDistance===""} onClick={handleDistanceSubmit} sx={{width:120, alignSelf:'center'}} variant="contained">Submit</Button>
          </Stack>
        </Box>

    </Modal>
      <Grid
        sx={{
          background: `${theme.palette.primary.main} !important`,
        }}
        container
        mb={2}
        p={1}
      >
        <Grid item xs={12} md={8} p={2} marginX={"auto"}>

          <Box
            display={isMobile ? "block" : "flex"}
            justifyContent={"space-between"}
            >
            <Stack>
              <Stack alignItems={"center"} direction={"row"}>
              { currentTenant === "wemove" || currentTenant ==='sold'? (
          <>
         <img src={logoWeMoveWhite} width={175} alt="" />
          </>
        ) : currentTenant === "turbotenant" ? (
            <img src={TurboTenantLogo} width={150} height={44} alt="" />
        ) : currentTenant === 'movebees'? (<img src={MoveBeesLogo} width={150} height={44} alt="" /> ) 
        : currentTenant === 'summitmoving'? (<img src={SummitMovingLogo} width={150} height={44} alt="" /> ) 
        : currentTenant === 'krupp'? (<img src={KruppLogo} width={150} height={44} alt="" /> ) 
        :(<></>)
        }
              </Stack>
              <Typography
                fontSize={14}
                fontWeight={600}
                mt={2}
                ml={0.5}
                color={"#FFFFFF"}
              >
                {getDate()}
              </Typography>
            </Stack>

            <Typography fontSize={28} fontWeight={700} color={"#FFFFFF"}>
              Summary of the Move
            </Typography>
          </Box>

          {moveRequest && (
            <>
              <Stack mt={5} spacing={1.5}>
                <Box
                  sx={{
                    p: 0.5,
                    borderBottom: "0.5px solid #D9D9D9",
                    background: "#F4F1DE",
                  }}
                >
                  <Typography
                    fontSize={16}
                    fontWeight={600}
                    ml={1}
                    color={"#797979"}
                  >
                    Including Service
                  </Typography>
                </Box>

                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={16} fontWeight={600} color={"#FFFFFF"}>
                    Move Type
                  </Typography>
                  <Typography fontSize={14} fontWeight={600} color={"#FFFFFF"}>
                    {moveRequest?.move_type}
                  </Typography>
                </Stack>

                <Divider />

                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={16} fontWeight={600} color={"#FFFFFF"}>
                    Pickup Location Address
                  </Typography>
                  <Typography fontSize={14} fontWeight={600} color={"#FFFFFF"}>
                    {checkFromAddress(moveRequest)}
                  </Typography>
                </Stack>

                <Divider />

                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={16} fontWeight={600} color={"#FFFFFF"}>
                    Delivery Location Address
                  </Typography>
                  {moveRequest?.delivery_details?.delivery_addresses.map(
                    (item: any) => (
                      <Typography
                        key={item.id}
                        fontSize={14}
                        fontWeight={600}
                        color={"#FFFFFF"}
                      >
                        {item?.complete_address}
                      </Typography>
                    )
                  )}
                </Stack>

                <Divider />

                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={16} fontWeight={600} color={"#FFFFFF"}>
                    Move Distance
                  </Typography>
                  <Typography fontSize={14} fontWeight={600} color={"#FFFFFF"}>
                    {moveDistance===0.0? 'unknown': moveDistance===null? 'unknown' : Math.ceil(moveDistance)}
                    {" mile(s)"}
                  </Typography>
                </Stack>

                <Divider />

                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={16} fontWeight={600} color={"#FFFFFF"}>
                    Shuttle Required
                  </Typography>
                  <Typography fontSize={14} fontWeight={600} color={"#FFFFFF"}>
                    {moveRequest?.delivery_details.shuttle_required==='YES'
                      ? "YES": moveRequest?.delivery_details.shuttle_required==='NO'? 'NO'
                      : "UNSURE"}
                  </Typography>
                </Stack>

                <Divider />

                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={16} fontWeight={600} color={"#FFFFFF"}>
                    Can We Get A Truck In Front Of Your Location?
                  </Typography>
                  <Typography fontSize={14} fontWeight={600} color={"#FFFFFF"}>
                    {moveRequest?.delivery_details.open_location==='YES' ? "YES" :
                    moveRequest?.delivery_details.open_location==='NO'? 'NO':
                    "UNSURE"}
                  </Typography>
                </Stack>

                <Divider />

                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={16} fontWeight={600} color={"#FFFFFF"}>
                    FADD (First Available Date of Delivery)
                  </Typography>
                  <Typography fontSize={14} fontWeight={600} color={"#FFFFFF"}>
                    {moveRequest?.first_available_date_of_delivery
                      ? formatDate(
                          moveRequest?.first_available_date_of_delivery
                        )
                      : "-"}
                  </Typography>
                </Stack>

                <Divider />

                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={16} fontWeight={600} color={"#FFFFFF"}>
                    Total Price Of The Items To Be Moved
                  </Typography>
                  <Typography fontSize={14} fontWeight={600} color={"#FFFFFF"}>
                    ${Math.ceil(priceOfItemsOnly)}
                  </Typography>
                </Stack>

                <Divider />

                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={16} fontWeight={600} color={"#FFFFFF"}>
                    Packaging Required
                  </Typography>
                  <Typography fontSize={14} fontWeight={600} color={"#FFFFFF"}>
                    {moveRequest?.delivery_details?.packagaing_required
                      ? "YES"
                      : "NO"}
                  </Typography>
                </Stack>

                <Divider />

                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={16} fontWeight={600} color={"#FFFFFF"}>
                    Packaging Type Selected By Consumer
                  </Typography>
                  <Typography fontSize={14} fontWeight={600} color={"#FFFFFF"}>
                    {moveRequest?.delivery_details?.packaging?.packaging_type ||
                      "-"}
                  </Typography>
                </Stack>

                <Divider />

                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={16} fontWeight={600} color={"#FFFFFF"}>
                    Total Price Of Packaging Consumer Has To Pay
                  </Typography>
                  <Typography fontSize={14} fontWeight={600} color={"#FFFFFF"}>
                    {moveRequest?.delivery_details?.packaging?.packaging_payment
                      ? "$" +
                        moveRequest?.delivery_details?.packaging
                          ?.packaging_payment
                      : "-"}
                  </Typography>
                </Stack>

                <Divider />

                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={16} fontWeight={600} color={"#FFFFFF"}>
                    Total Price Of The Move
                  </Typography>
                  <Typography fontSize={14} fontWeight={600} color={"#FFFFFF"}>
                    ${Math.ceil(totalMovePrice)}
                  </Typography>
                </Stack>

                <Divider />

                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={16} fontWeight={600} color={"#FFFFFF"}>
                    Payment To Confirm The Move
                  </Typography>
                  <Typography fontSize={14} fontWeight={600} color={"#FFFFFF"}>
                    ${Math.ceil(weMoveCommision)}
                  </Typography>
                </Stack>

                <Divider />
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={16} fontWeight={600} color={"#FFFFFF"}>
                    Number of estimated boxes
                  </Typography>
                  <Typography fontSize={14} fontWeight={600} color={"#FFFFFF"}>
                     {moveRequest?.no_of_boxes_for_boxable_items}
                  </Typography>
                </Stack>

                <Divider />
                

                <Stack>
  <Stack direction={'row'} justifyContent={'space-between'}>
    <Typography sx={{textDecoration: 'underline'}} fontSize={16} fontWeight={600} color={"#FFFFFF"}>
      Bulky Items
    </Typography>
    <Typography sx={{textDecoration: 'underline'}} fontSize={16} fontWeight={600} color={"#FFFFFF"}>
      Bulky Fee
    </Typography>
  </Stack>
  {moveRequest.roomDetails ? (
    (() => {
      let hasBulkyItems = false;
      const bulkyItems = moveRequest.roomDetails.map((room: any) => (
        <Box key={room.id}>
          {room.room_images?.map((image: any, imageIndex: number) => (
            <Box key={image.id}>
              {image.items?.map((item: any, index: number) => {
                if (item.is_bulky === true && item.bulky_fee !== 0) {
                  hasBulkyItems = true;
                  return (
                    <Stack key={item.id}>
                      <Divider />
                      <Stack marginY={0.5} direction={'row'} justifyContent={'space-between'}>
                        <Typography fontSize={14} fontWeight={600} color={"#FFFFFF"}>- {item.item_name}</Typography>
                        <Typography fontSize={14} fontWeight={600} color={"#FFFFFF"}>${item.bulky_fee}</Typography>
                      </Stack>
                    </Stack>
                  );
                }
                return null; // Add this to avoid returning undefined
              })}
            </Box>
          ))}
        </Box>
      ));

      return hasBulkyItems ? bulkyItems : (
        <Typography textAlign={'center'} fontSize={14} fontWeight={600} color={"#FFFFFF"}>No bulky items</Typography>
      );
    })()
  ) : (
    <Typography fontSize={14} fontWeight={600} color={"#FFFFFF"}>-</Typography>
  )}
</Stack>
                

                  
                  

                    <Typography fontSize={16} fontWeight={600} color={"#FFFFFF"}>
                  List Of All Items Consumer Is Moving:
                </Typography>
                

                {moveRequest.roomDetails ? (
                  moveRequest?.roomDetails?.map((room: any) => {
                    return (
                      <Box key={room.id} mb={2}>
                        <Typography fontSize={14} fontWeight={600} color={"#FFFFFF"}>
                         Items For {room.title ? room.title + ":" : "No name given to room:"}
                        </Typography>
                        {room.room_images?.map((image: any, imageIndex:number) => (
                          <Box key={image.id} ml={2} mt={1}>
                           <Typography color={'#FFFFFF'} fontWeight={600} sx={{textDecoration:'underline'}} fontSize={14} >Image {imageIndex+1}:</Typography>
                            {image.items?.map((item: any, index:number) => (
                              <Typography key={item.id} fontSize={14} fontWeight={600} color={"#FFFFFF"} ml={4}>
                               {index+1}. {item.item_name}
                              </Typography>
                            ))}
                          </Box>
                        ))}
                      </Box>
                    );
                  })
                ) : (
                  <Typography>-</Typography>
                )}

                <Divider />
              </Stack>
            </>
          )}

          <Box mt={4}>
            {moveRequest?.contract_signed===null || moveRequest?.contract_signed===false?
            <Stack alignItems={'center'} direction="row" mt={2} spacing={2} justifyContent={"center"}>
              <Typography
              fontSize={18}
              fontWeight={700}
              color={"#FFFFFF"}
              textAlign={"center"}
            >
              DO YOU WANT TO PROCEED?
            </Typography>
              <Button
                size="large"
                sx={notSeelectedButtonStyle}
                onClick={() => {
                  navigate(`/itemsInfo/${moveRequest.canonical_id}`)
                }}
                >
                No
              </Button>

              <Button
                size="large"
                onClick={() => {
                  console.log("Button clicked=>")
                  if(moveRequest.initial_deposit===null){
                    console.log("Passed intial deposit check=>")
                    updateThisMoveRequest({
                      move_payment: Math.ceil(Number(totalMovePrice)),
                      price_of_items: Number(Math.ceil(priceOfItemsOnly)),
                      move_distance: Math.ceil(Number(moveDistance))
                    });
                  }
                  }}
                  sx={selectedButtonStyle}
                  >
                Yes
              </Button>
            </Stack>
              :(
                <Stack direction="row" mt={2} spacing={2} alignItems={'center'} justifyContent={"center"}>
                  <Stack spacing={0.5} direction={'row'} alignItems={'center'}>
                  <CheckCircleIcon sx={{color:"#46A577", alignSelf:'center'}} fontSize="medium"/>
                  <Typography
              fontSize={18}
              fontWeight={700}
              color={"#FFFFFF"}
              textAlign={"center"}
              >
            YOU HAVE ALREADY SIGNED THE CONTRACT FOR THE MOVE.
            </Typography>
              </Stack>
                <Button
                  size="large"
                  
                  onClick={() => {
                      if(moveRequest?.initial_deposit!==null){
                        navigate(`/ConsumerDashboard/${moveRequest.user.canonical_id}`)
                      }else{
                        if(moveRequest.initial_deposit===null){
                          // navigate(`/contract/${moveRequest.canonical_id}`)
                          confirmMovePayment();
                        }
                      }
                    }}
                    sx={selectedButtonStyle}
                    >
                      {moveRequest?.initial_deposit!==null? 'GO TO DASHBOARD' : 'PAY TO CONFIRM'}
                </Button>
              </Stack>
              )}
          </Box>
          <Stack mt={3} alignSelf={'center'} justifyContent={'center'} alignItems={'center'}>
          <ContactSupport/>
          </Stack>

          {isPageLoading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isPageLoading}
              onClick={() => {
                setIsPageLoading(true);
              }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Grid>

      </Grid>
    </>
  );
};

export default MoveSummary;

import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Stack,
  TextField,
  Typography,
  Alert,
  useTheme,
  useMediaQuery,
  IconButton,
  Divider
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getMoveRequestById, shouldAutoRedirect } from "../../redux/actions/move-request";
import { unwrapResult } from "@reduxjs/toolkit";
import WeMoveHeader from "../../components/header/weMoveHeader";
import Backdrop from "@mui/material/Backdrop";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import * as AWS from "aws-sdk";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { createRoomDetails, fetchInventoryFromVideo } from "../../redux/actions/room-details";
import UploadCompletedModal from "../../components/modals/uploadCompletedModal";
import imageCompression from 'browser-image-compression';
import ReactGA from 'react-ga4';
import RepeatIcon from '@mui/icons-material/Repeat';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ContactSupport from "../../components/contactSupport";
import NavBar from "../../components/navbar/navBar";


interface PayloadObject {
  title: string;
  images: File[]; // Specify the type as File[]
}
const awsS3Bucket: string = process.env.REACT_APP_AWS_S3_BUCKET || "";
const awsAccessKey = process.env.REACT_APP_AWS_S3_ACCESS_KEY;
const awsSecretKey = process.env.REACT_APP_AWS_S3_KEY_SECRET;

const s3 = new AWS.S3({
  accessKeyId: awsAccessKey,
  secretAccessKey: awsSecretKey,
  region:process.env.REACT_APP_AWS_S3_REGION
});

const inventoryArray = [
  {
    object_type_name: "chair",
    predicted_dimensions: [5.88, 6.2257, 3.5],
    count: 3,
  },
  {
    object_type_name: "frame",
    predicted_dimensions: [0.25, 2.0, 0.05],
    count: 2,
  },
  {
    object_type_name: "carpet",
    predicted_dimensions: [6.0, 8.0, 2.0],
    count: 1,
  },
  {
    object_type_name: "lamp",
    predicted_dimensions: [0.8474, 1.9967, 1.1997],
    count: 1,
  },
  {
    object_type_name: "sofa",
    predicted_dimensions: [5.97477064201835, 6.0848, 4.847],
    count: 3,
  },
  {
    object_type_name: "cushion",
    predicted_dimensions: [
      2.579524421411253, 2.022740462127213, 2.648165217409983,
    ],
    count: 1,
  },
  {
    object_type_name: "table",
    predicted_dimensions: [6.5848, 8.5, 2.415829695],
    count: 1,
  },
];

const UploadInventory = () => {
  
  const [url, setUrl] = useState(process.env.REACT_APP_URL || "")

  if(url==='https://stagingapi.wemoveai.com/'){
    ReactGA.send({
      hitType: 'pageview',
      page:"/upload-inventory/:moveRequestId",
      title:"Upload Inventory Staging"
    })
  } 
   
  if(url==='https://productionapi.wemoveai.com/'){
    ReactGA.send({
      hitType: 'pageview',
      page:"/upload-inventory/:moveRequestId",
      title:"Upload Inventory Production"
    })
  }  

  if(url==='https://turbotenantapi.wemoveai.com/'){
    ReactGA.send({
      hitType: 'pageview',
      page:"/upload-inventory/:moveRequestId",
      title:"Upload Inventory Turbotenant"
    })
  }  

  if(url==='https://soldapi.wemoveai.com/'){
    ReactGA.send({
      hitType: 'pageview',
      page:"/upload-inventory/:moveRequestId",
      title:"Upload Inventory Sold"
    })
  }  


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const { moveRequestId } = useParams();
  const [moveRequest, setMoveRequest] = useState<any>(null);
  const [thisMoveRequestId, setThisMoveRequestId] = useState(null);
  // const [jwt, setJwt] = useState<any>("")
  const [totalVideos, setTotalVideos] = useState<any>([]);
  const [videoCount, setVideoCount] = useState(0);
  const [vidCount, setVidCount] = useState(0);
  const [videoTitlesArray, setVideoTItlesArray] = useState([""]);

  const [nextState, setNextState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [uploadCompletedModalVisible, setUploadCompletedModalVisible] = useState(false)
  const [imagePreviews, setImagePreviews] = useState<string[][]>([]);

  // const tokenValue = new URLSearchParams(window.location.search).get(
  //   "tokenvalue"
  // );
  const sessionId = new URLSearchParams(window.location.search).get("sessionId")

  useEffect(() => {
    if (sessionId) {
      localStorage.setItem('sessionId', sessionId);
      localStorage.setItem('flowStartedOn', 'PC');
      const urlWithoutSessionId =
      window.location.pathname +
      window.location.search
        .split("&")
        .filter((param) => !param.includes("sessionId"))
        .join("&");
    window.history.replaceState(null, "", urlWithoutSessionId);
    } else if (isMobile && !localStorage.getItem('sessionId')) {
      localStorage.setItem('flowStartedOn', 'Mobile');
    }else if(!isMobile && !localStorage.getItem('sessionId')){
      localStorage.setItem('flowStartedOn', 'PC');
    }

    // if (!isMobile && localStorage.getItem('flowStartedOn') === 'PC') {
    //   alert("Please complete this task on your mobile device.");
    // }
  }, [sessionId]);

  const uploadFile = async (file: any, fileName: any) => {
    const formData = new FormData();
    formData.append("file", file);
    return await s3_upload(formData, fileName, file.name);
  };

  const s3_upload = async (
    formData: any,
    fileName: any,
    originalName: string
  ) => {
    // const contentType = getContentType(originalName);
    const uploadParams = {
      Bucket: awsS3Bucket,
      Key: fileName,
      Body: formData.get("file"),
      ContentType: "image/png",
    };
    return new Promise((resolve, reject) => {
      s3.upload(uploadParams, (err: any, data: any) => {
        if (err) {
          reject(err);
        }
        resolve(data);
      });
    });
  };

  const getContentType = (fileName: string) => {
    const extension = fileName.split(".").pop()?.toLowerCase();
    switch (extension) {
      case "mov":
        return "video/quicktime";
      case "mp4":
        return "video/mp4";
      // Add more cases for other video types if needed
      default:
        return "video/mp4"; // Default content type for unknown extensions
    }
  };

  const retrieveFormDataAndSaveToS3 = async (
    file: any,
    fileName: string,
    videoTitle: string
  ) => {
    setIsLoading(true);
    setLoadingMessage(`Uploading Image...`);

    const responseObject: any = await uploadFile(file, fileName);
    setLoadingMessage(`Fetching inventory...`);

    if (responseObject.Location) {
      const body = {
        moveRequestId: thisMoveRequestId,
        video_url: responseObject.Location,
        title: videoTitle,
      };

      dispatch<any>(fetchInventoryFromVideo(body))
        .then(unwrapResult)
        .then((res: any) => {
          setVideoCount((videoCounter) => videoCounter + 1);
        })
        .catch((err: any) => {
          console.log(err);
          setIsLoading(false);
          setLoadingMessage("");
        });
    }
  };
  const [formError, setFormError] = useState("")
  const handleTitleChange = (title: string, index: any) => {
    const newTitles = [...videoTitlesArray];
    newTitles[index] = title;
    setVideoTItlesArray(newTitles);
  };

  const handleAddMore = () => {
    const allFilled = totalVideos.every((video: any) => video.name);
    if (allFilled) {
      setTotalVideos([...totalVideos, {}]);
    } else {
      console.warn("Please fill all previous video fields before adding more.");
    }
  };

  const handleFileChange = (event: any, index: any) => {
    setNextState(false)
    const newSelectedFiles = [...totalVideos];
    let count = index + 1;
    newSelectedFiles[index] = event.target.files[0];
    setTotalVideos(newSelectedFiles);
  };

  const countTotalVideos = () => {
    totalVideos.map((video: any) => {
      if (video.type) {
        setVidCount((count) => count + 1);
      }
    });
  };

  const handleUploadVideos = () => {
    totalVideos.map((video: any, index: any) => {
      if (video.type) {
        setNextState(true);
        let count = index + 1;
        retrieveFormDataAndSaveToS3(
          video,
          "room-videos/" +
            moveRequest.canonical_id.toString() +
            `/video-index${count.toString()}x`,
          videoTitlesArray[index]
        );
      }
    });
  };

  const uploadToS3 = async(thisImage:any, index:number, title:string, roomId:number) => {
    let count = index + 1;
    const imageName = `room-images/${moveRequest.canonical_id}/${title}/image-index/${count}`
    setLoadingMessage(`Uploading Image(s)...
This may take a few minutes. Please wait...`);
    const responseObject:any = await uploadFile(thisImage, imageName)
    if (responseObject.Location) {

      const body = {
        moveRequestId: thisMoveRequestId,
        roomDetailId: roomId,
        createRoomImagesDto: {
          image_url: responseObject.Location
        }

      };
    setLoadingMessage(`Fetching inventory...`);
      dispatch<any>(fetchInventoryFromVideo(body))
        .then(unwrapResult)
        .then((res: any) => {
          // setVideoCount((videoCounter) => videoCounter + 1);
          setProcessedImages((processedCount) => processedCount + 1)
        })
        .catch((err: any) => {
          console.log(err);
          setIsLoading(false);
          setLoadingMessage("");
        });
    }
  }
  const getResponseFromS3ToGetInventory = async (payload:any, roomId:number) => {
    setIsLoading(true);
    setLoadingMessage(`Uploading Image(s)...
This may take a few minutes. Please wait...`);
    payload.images.map(async(image:any, index:number)=>{
    setIsLoading(true);

     await uploadToS3(image,index,payload.title, roomId)
    })

    // setLoadingMessage(`Fetching inventory...`);\
    // setIsLoading(false)
  };
  const [imageCount, setImageCount] = useState(0)
  const [processedImages, setProcessedImages] = useState(0)
  const countImages = () =>{
    payloadObjects.map((payload:any)=>{
      payload.images.map((img:any)=>{
        if(img.type){
          setImageCount((imgCount)=> imgCount + 1)
        }
      })
    })
  }
  useEffect(()=>{
    if(processedImages === imageCount && processedImages!==0){
      setIsLoading(false)
      if(localStorage.getItem('flowStartedOn') === 'PC' && isMobile){
        dispatch<any>(shouldAutoRedirect())
        .then(unwrapResult)
        .then((res:any)=>{
          console.log('Redirect Signal sent!')
          //open a modal
          setUploadCompletedModalVisible(true)
        }).catch((err:any)=>{
          console.log(err)
        })
      } else if(localStorage.getItem('flowStartedOn') === 'PC' && !isMobile){
         if (localStorage.getItem('action-type') === "edit"){
          navigate(`/modifyItems/${moveRequest.canonical_id}`)
        } else if (localStorage.getItem('action-type') === "add-item"){
          navigate(`/itemsInfo/${moveRequest.canonical_id}`)
        } else {
          navigate(`/AddressInfo/${moveRequest.canonical_id}`);
        }
      }else if(localStorage.getItem('flowStartedOn') === 'Mobile' && isMobile){
        if (localStorage.getItem('action-type') === "edit"){
          navigate(`/modifyItems/${moveRequest.canonical_id}`)
        } else if (localStorage.getItem('action-type') === "add-item"){
          navigate(`/itemsInfo/${moveRequest.canonical_id}`)
        } else {
          navigate(`/AddressInfo/${moveRequest.canonical_id}`);
        }
      }
    }
  //   else{
  //   if(processedImages === imageCount && processedImages!==0 && moveRequest.roomDetails.length !== 0){
  //     navigateToLastLeft(moveRequest)
  //   }
  // }
  },[processedImages])

  useEffect(()=>{
    console.log(localStorage.getItem('action-type'))
    console.log(localStorage.getItem('action-type') === "edit")
  },[])
  const handleUploadImages = () => {
    if(payloadObjects.every((payload:any)=> payload.title&& payload.images.length && payload.images?.every((img:any)=> img.type))){
      countImages();
      payloadObjects.map((payload:any)=>{
        setIsLoading(true)
        setLoadingMessage(`Uploading Image(s)...
This may take a few minutes. Please wait...`);
      const body = {
        moveRequestId: thisMoveRequestId,
        title: payload.title,
      };
      dispatch<any>(createRoomDetails(body))
      .then(unwrapResult)
      .then((res:any)=>{

          getResponseFromS3ToGetInventory(payload,res.id)
        }).catch((err:any)=>{
          console.log(err)
        })
    })
      
    }else{
      setIsLoading(false)
      setFormError("Please capture or select at least one image for each room you have added. ")
    }
    // payloadObjects.map((payload:any)=>{
    //   if(payload.images.length!==0 &&payload.images.every((img:any)=>img.type)){
    //     setIsLoading(true)
    //   const body = {
    //     moveRequestId: thisMoveRequestId,
    //     title: payload.title,
    //   };
    //   dispatch<any>(createRoomDetails(body))
    //   .then(unwrapResult)
    //   .then((res:any)=>{

    //       getResponseFromS3ToGetInventory(payload,res.id)
    //     }).catch((err:any)=>{
    //       console.log(err)
    //     })
    //   }else{
    //     setIsLoading(false)
    //     setFormError("Please capture or select at least one image for each room you have added. ")
    //   }
    // })
    }

  const fetchMoveRequestById = (moveRequestId: any) => {
    dispatch<any>(getMoveRequestById(moveRequestId))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        setMoveRequest(thisMoveRequest);
        setThisMoveRequestId(thisMoveRequest.id);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   localStorage.setItem('role', 'consumer')

  //   if (moveRequestId && jwt!=="") {
  //     fetchMoveRequestById(moveRequestId);
  //   }
  // }, [moveRequestId,jwt]);

  useEffect(() => {
    localStorage.setItem('role', 'consumer')

    if (moveRequestId) {
      fetchMoveRequestById(moveRequestId);
    }
  }, [moveRequestId]);

  // useEffect(()=>{
  //   if(jwt===""){
  //     setJwt(localStorage.getItem('jwtToken'))
  //   }
  // },[])
  // useEffect(() => {
  //   if (tokenValue) {
  //     localStorage.setItem("jwtToken", tokenValue);
  //     const thisToken = localStorage.getItem("jwtToken");
  //     if (thisToken) {
  //       const urlWithoutToken =
  //         window.location.pathname +
  //         window.location.search
  //           .split("&")
  //           .filter((param) => !param.includes("tokenvalue"))
  //           .join("&");
  //       window.history.replaceState(null, "", urlWithoutToken);
  //     }
  //     setJwt(localStorage.getItem('jwtToken'))
  //   }
  // }, [tokenValue]);
  const navigateToLastLeft = (moveRequest: any) => {
    if (!moveRequest.delivery_details) {
      navigate(`/AddressInfo/${moveRequest.canonical_id}`);
    } else {
      if (moveRequest.delivery_details?.delivery_addresses?.length === 0) {
        navigate(`/AddressInfo/${moveRequest.canonical_id}`);
      } else {
        if (
          moveRequest.delivery_details?.delivery_addresses[0].stiars_present ===
            null ||
          moveRequest.delivery_details?.delivery_addresses[0]
            .is_elevator_accessible === null
        ) {
          navigate(`/DeliveryLocationInfo/${moveRequest.canonical_id}`);
        } else {
          if (
            moveRequest.pickup_date_from === null &&
            moveRequest.pickup_date_to === null
          ) {
            navigate(`/PickupDate/${moveRequest.canonical_id}`);
          } else {
            if (moveRequest.delivery_details.open_location === null) {
              navigate(`/TruckInfo/${moveRequest.canonical_id}`);
            } else {
              navigate(`/itemsInfo/${moveRequest.canonical_id}`); 
          }
         }
        }
      }
    }
  };

  // useEffect(() => {
  //   if (videoCount === vidCount && videoCount !== 0 && moveRequest?.roomDetails?.length===0) {
  //     setIsLoading(false);
      // navigate(`/AddressInfo/${moveRequest.canonical_id}`);
  //   }else{
  //     if(videoCount === vidCount && videoCount !== 0&& moveRequest?.roomDetails?.length!==0){
      // navigateToLastLeft(moveRequest)
  //     // navigate(`/itemsInfo/${moveRequest.canonical_id}`)
  //     }
  //   }
  // }, [videoCount]);

  const [payloadObjects, setPayloadObjects] = useState<PayloadObject[]>([{ title: '', images: [] }]);
  const [newTitle, setNewTitle] = useState('');

  // Function to handle adding a new payload object
  const addPayloadObject = () => {
    setPayloadObjects([...payloadObjects, { title: '', images: [] }]);
  };

  // Function to handle title change
  const handleTitleChange1 = (index:any, value:any) => {
    setFormError("")
    const updatedPayloadObjects = [...payloadObjects];
    updatedPayloadObjects[index].title = value;
    setPayloadObjects(updatedPayloadObjects);
  };

  // Function to handle adding an image to a payload object
  const addImageToPayload = async (index:any, image:any, imageIndex:any) => {
    setFormError("")
    setIsLoading(true)
    setLoadingMessage("Compressing Image...")
    const updatedPayloadObjects: PayloadObject[] = [...payloadObjects]; // Specify the type of updatedPayloadObjects
  const file: File = image.target.files[0]; // Specify the type of file
  
    const previewUrl = URL.createObjectURL(file);

    // Options for image compression
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 2000,
      useWebWorker: true,
    };

    // Compress the image file
    const compressedFile = await imageCompression(file, options);
    if(compressedFile){
      // Update the payload with the compressed file
      updatedPayloadObjects[index].images[imageIndex] = compressedFile;
      setPayloadObjects(updatedPayloadObjects);
    }else{
         updatedPayloadObjects[index].images[imageIndex] = file;
         setPayloadObjects(updatedPayloadObjects);
    }

    const updatedPreviews = [...imagePreviews];
    if (!updatedPreviews[index]) updatedPreviews[index] = [];
    updatedPreviews[index][imageIndex] = previewUrl;
    setImagePreviews(updatedPreviews);

    setIsLoading(false)
    setLoadingMessage("")
  
  };
  // const addImageToPayload = (index:any, image:any, imageIndex:any) => {
  //   setFormError("")
  //   const updatedPayloadObjects: PayloadObject[] = [...payloadObjects]; // Specify the type of updatedPayloadObjects
  // const file: File = image.target.files[0]; // Specify the type of file
  //   updatedPayloadObjects[index].images[imageIndex] = file;
  //   setPayloadObjects(updatedPayloadObjects);
  // };

  // Function to handle adding a new image input
  const addImageInput = (index: number) => {
    const updatedPayloadObjects = [...payloadObjects];
    if (updatedPayloadObjects[index].images.length === 0 || updatedPayloadObjects[index].images.slice(-1)[0].type!==undefined) {
    updatedPayloadObjects[index].images.push(new File([], ''));
    setPayloadObjects(updatedPayloadObjects);
    }
  };
  const discardRoom = (index: number) => {
    const updatedPayloadObjects = [...payloadObjects];
    updatedPayloadObjects.splice(index, 1);
    setPayloadObjects(updatedPayloadObjects);
  };
  
  const discardImage = (roomIndex: number, imageIndex: number) => {
    const updatedPayloadObjects = [...payloadObjects];
    
    const updatedPreviews = [...imagePreviews];

  // Clean up the preview URL
  URL.revokeObjectURL(updatedPreviews[roomIndex][imageIndex]);
  updatedPreviews[roomIndex].splice(imageIndex, 1);
  setImagePreviews(updatedPreviews);



    updatedPayloadObjects[roomIndex].images.splice(imageIndex, 1);
    setPayloadObjects(updatedPayloadObjects);
  };
  return (
    <>
    
    <NavBar moveRequestId={moveRequestId} />
      <Grid container p={1}>
        <Grid item xs={12} md={5} p={2} marginX={"auto"}>
          {isLoading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
              onClick={() => {
                setIsLoading(true);
              }}
            >
              <Box sx={{ width: "50%" }}>
                <LinearProgress />
                <Typography
                fontSize={16}
                fontWeight={500}
                textAlign="center"
                mt={1}
              >
                {loadingMessage && loadingMessage.split('\n').map((line, index) => (
                  <Typography key={index} component="span" display="block">
                    {line}
                  </Typography>
                ))}
          </Typography>
              </Box>
            </Backdrop>
          )}

{uploadCompletedModalVisible && (
  <UploadCompletedModal
    setUploadCompletedModalVisible={setUploadCompletedModalVisible}
    uploadCompletedModalVisible={uploadCompletedModalVisible}
  />
)}
{process.env.REACT_APP_TENANT==='wemove'||process.env.REACT_APP_TENANT==='turbotenant'&&(
        <Stack mt={3} alignSelf={'center'} justifyContent={'center'} alignItems={'center'}>
            <ContactSupport/>
          </Stack>
          )}
<Box p={1} borderRadius={4} sx={{background: "#dcd6f4"}}>
  <Typography fontSize={18} fontWeight={700} textAlign="center">
    Let's Get You Moving!
  </Typography>

  <Typography fontSize={16} marginTop={2}>
    <ol style={{ listStyleType: "counting" }}>
      <li>Name the Room you're in.</li>
      <li>
        <CameraAltIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
        Take 1-2 pictures per room to ensure all large furniture is captured.
      </li>
      <li>
        Repeat <RepeatIcon color="primary" sx={{ verticalAlign: 'middle', mr: 1 }} /> 
        step 1 & 2 until all the rooms are captured.
      </li>
      <li>
        Hit Submit <Button size="small" variant="contained" sx={{ verticalAlign: 'middle',"&:hover": {
          backgroundColor: "#000000", // Hover background color
          color: "#FDFCFD", // Hover text color
        } }}>Submit</Button> and in 10-15 sec inventory will be complete <CheckBoxIcon color="secondary" sx={{ verticalAlign: 'middle' }} />
      </li>
    </ol>
  </Typography>
</Box>


          {totalVideos.length > 0 &&
            totalVideos.map((stop: any, index: any) => (
              <Stack
                key={index}
                alignItems={"center"}
                direction={"row"}
                mt={3}
                justifyContent={"space-between"}
              >
                {/* <Typography fontSize={14}>Image {index + 1}</Typography> */}
                {/* <input
                  accept="video/*"
                  capture="environment"
                  id={`video-upload-${index}`}
                  type="file"
                  style={{ display: "none" }}
                  onChange={(event) => handleFileChange(event, index)}
                /> */}
                {/* <input
                  accept="image/*"
                  capture="environment"
                  id={`image-upload-${index}`}
                  type="file"
                  style={{ display: "none" }}
                  onChange={(event) => handleFileChange(event, index)}
                />


                <TextField
                  variant="outlined"
                  type="text"
                  label="Room Name"
                  sx={{ width: 180 }}
                  size="small"
                  value={videoTitlesArray[index] || ""}
                  onChange={(e) => {
                    handleTitleChange(e.target.value, index);
                  }}
                /> */}

                {/* <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <label htmlFor={`image-upload-${index}`}>
            <Button variant="contained" component="span">
              Select Image
            </Button>
          </label>

                  <Typography fontSize={14}>
                    {totalVideos[index]
                      ? totalVideos[index].name
                      : "No video selected"}
                  </Typography>
                </Stack> */}
              </Stack>
            ))}

          {/* <Stack mt={3} alignItems={"center"} spacing={1}>
            {totalVideos.length === 0 && (
              <Typography>
                No video fields yet. Click "Add" to begin.
              </Typography>
            )}

            <Button
              size="medium"
              onClick={handleAddMore}
              sx={{
                width: 90,
                color: "#5858E0",
                border: "1px solid #5858E0",
                whiteSpace: "nowrap",
                fontSize: 12,
              }}
            >
              <Stack alignItems="center">
                <AddIcon fontSize="small" />
                Add Room
              </Stack>
            </Button>
          </Stack> */}

          <Stack spacing={2} mt={2} justifyContent={"center"}>
            {/* {!nextState && (
              <Button
                variant="contained"
                onClick={() => {
                  countTotalVideos();
                  handleUploadVideos();
                }}
              >
                Confirm
              </Button>
            )} */}
            {/* {nextState && (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(`/AddressInfo/${moveRequest.canonical_id}`);
                }}
              >
                Next
              </Button>
            )} */}

{payloadObjects.map((payload, payloadIndex) => (
  <Stack spacing={2} key={payloadIndex}>
    <TextField
      variant="outlined"
      type="text"
      label="Room Name"
      sx={{ width: 180 }}
      size="small"
      value={payload.title}
      onChange={(e) => handleTitleChange1(payloadIndex, e.target.value)}
    />
    <Typography>{payload.images.filter(Boolean).length} image input(s) for this room.</Typography>

    {payload.images.map((image:any, imageIndex) => (
          <Stack spacing={1} alignItems="flex-start" key={imageIndex}>
        <Stack direction="row" spacing={1} alignItems="center">
            <IconButton
              color="primary"
              component="label"
              style={{ display: 'block', margin: '10px 0' }}
              >
              <Stack alignItems={'center'}>
              <AddAPhotoIcon />
              <input
                type="file"
                capture="environment"
                onChange={(e) => addImageToPayload(payloadIndex, e, imageIndex)}
                accept="image/*"
                style={{ display: 'none' }}
                />
            <Typography variant="caption">Take Photo</Typography>
                </Stack>
            </IconButton>

          <IconButton
            color="secondary"
            component="label"
            style={{ display: 'block', margin: '10px 0' }}
            >
            <Stack alignItems={'center'}>

            <FileUploadIcon />
            <input
              type="file"
              onChange={(e) => addImageToPayload(payloadIndex, e, imageIndex)}
              accept="image/*"
              style={{ display: 'none' }}
              />
            <Typography variant="caption">Upload</Typography>
              </Stack>
          </IconButton>      
        </Stack>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          key={imageIndex}
          sx={{ width: '100%' }}
        >
          {imagePreviews[payloadIndex] && imagePreviews[payloadIndex][imageIndex] && (
            <img
              src={imagePreviews[payloadIndex][imageIndex]}
              alt={`Preview ${imageIndex + 1}`}
              style={{ width: 100, maxHeight: 100, objectFit: 'cover' }}
            />
          )}

          <Typography
            variant="body2"
            sx={{
              maxWidth: '70%', // Adjust the width as needed
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: theme.palette.primary.main,
              fontWeight:600
            }}
          >
            File name: {image.name || 'No file selected'}
          </Typography>
            <Stack >
            <IconButton
              color="error"
              onClick={() => discardImage(payloadIndex, imageIndex)}
              >
              <Stack alignItems={'center'}>
              <DeleteIcon />
              </Stack>
            </IconButton>
              </Stack>
        </Stack>
        <Divider sx={{ borderColor: '#D3D3D3', width:'70%', alignSelf:'center' }} />
            </Stack>
      ))}

    <Button
      sx={{ width: 140, "&:hover": {
          backgroundColor: "#000000", // Hover background color
          color: "#FDFCFD", // Hover text color
        } }}
      variant="contained"
      onClick={() => addImageInput(payloadIndex)}
      disabled={payload.images.length > 0 && payload.images[payload.images.length - 1].type === ""}
    >
      Add Image +
    </Button>

    {payloadIndex !== 0 && (
      <Button
        sx={{ width: 140 }}
        variant="contained"
        color="error"
        onClick={() => discardRoom(payloadIndex)}
      >
        Discard Room
      </Button>
    )}

    {payloadIndex === payloadObjects.length - 1 && (
      <Button
        disabled={payloadObjects.length > 0 && (payload.title === "" || payload.images.some((image) => image.type === ""))}
        variant="contained"
        onClick={addPayloadObject}
      >
        Add Room +
      </Button>
    )}
  </Stack>
))}



            {formError && <Alert severity="error">{formError}</Alert>}

      <Button variant="contained" sx={{"&:hover": {
          backgroundColor: "#000000", // Hover background color
          color: "#FDFCFD", // Hover text color
        }}} onClick={() => {
        handleUploadImages();
        }}>Submit</Button>

        {/* {localStorage.getItem('action-type') === "edit" && (
                <Button variant="outlined"   onClick={() => {
                  navigate(-1)
                }}>cancel</Button>
        )} */}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default UploadInventory;

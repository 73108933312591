import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack, TextField, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { updateMoveRequestById } from "../../redux/actions/move-request";
import { getInventoryVolume } from "../../redux/actions/room-details";
import { useState } from "react";
import pushMoveDataToCRM from "../../assets/utils/pushMoveData";

const UserInfoModal = ({
  isUserInfoModal,
  setIsUserInfoModal,
  moveRequestId,
  thisMoveRequestId,
  setIsFinalizeFlowModal,
  setIsPageLoading,
  setIsPackaginServiceModal,
  moveRequest
}: any) => {
  const sendDataToCrm = async(thisMoveRequest:any) => {
    const tenant = process.env.REACT_APP_TENANT||'';
    await pushMoveDataToCRM(thisMoveRequest,tenant)
    navigate(`/booked-move/${moveRequestId}`)
  }
    const updateThisMoveRequest = (body: any) => {
        setIsPageLoading(true);
        const params = {
          id: thisMoveRequestId,
          ...body,
        };
        dispatch<any>(updateMoveRequestById(params))
          .then(unwrapResult)
          .then((thisMoveRequest: any) => {
            setTimeout(() => {
              setIsPageLoading(false);
              if(process.env.REACT_APP_URL==='https://production.wemoveai.com'||process.env.REACT_APP_URL==='https://sold.wemoveai.com'||process.env.REACT_APP_URL==='https://turbotenant.wemoveai.com'){
            setIsPackaginServiceModal(true);
              }
              else{
                if(process.env.REACT_APP_URL==='https://staging.wemoveai.com'){
                  setIsFinalizeFlowModal(true)
                }else {
                  if(process.env.REACT_APP_URL==='https://movebees.wemoveai.com'||process.env.REACT_APP_URL==='https://kruppmoving.wemoveai.com'||process.env.REACT_APP_URL==='https://summitmoving.wemoveai.com'){
                    sendDataToCrm(thisMoveRequest)
                  }
                } 
              }
              
            }, 1300);
          })
          .catch((err: any) => {
            console.log(err);
            setTimeout(() => {
              setIsPageLoading(false);
            }, 1300);
          });
      };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const handleClose = () => {
    setIsPageLoading(false)
    setIsUserInfoModal(false);
  }

  const handleNavigateToPackages = () => {
    navigate(`/packages/${moveRequestId}`);
  };
  const [emailError, setEmailError] = useState("")
  const validateEmail = (email: any) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email || !emailRegex.test(email)) {
      setEmailError("Invalid Email");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };
  const handleEmailChange = (e: any) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (!validateEmail(newEmail)) {
      setEmailError("Invalid Email");
    } else {
      setEmailError("");
    }
  };

  return (
    <>
      <Modal
        open={isUserInfoModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "75vw" : "35vw",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 3,
          }}
        >
          <Typography
            mt={1}
            fontSize={18}
            fontWeight={700}
            color={"#262626"}
            lineHeight={1.2}
          >
         <br /> Please enter your first name and email, and our moving estimator will reach out to review your personalized quote and answer any questions you may have about the process.
          </Typography>
          <Typography mt={1} fontSize={14} color={"#797979"}>
          We're here to help make your move as smooth as possible!
          </Typography>
          <Box mt={3} display={"flex"} justifyContent={"center"}>
            <Stack spacing={2}>

            <TextField
                    label="Name"
                    variant="outlined"
                    type="text"
                    value={name}
                    fullWidth
                    margin="normal"
                    size="small"
                    onChange={(e) => {
                        setName(e.target.value)
                      }}
                  />
                   <TextField
                    label="Email"
                    variant="outlined"
                    type="text"
                    value={email}
                    fullWidth
                    margin="normal"
                    size="small"
                    error={Boolean(emailError)}
                    onChange={handleEmailChange}
                  />
              <Button
                onClick={() => {
                    if(name!==""&&email!==""&&validateEmail(email)){

                        updateThisMoveRequest({
                            mover_name: name,
                            mover_email: email
                        })
                    }else{
                        alert('Please enter the correct information')
                    }
                }}
                sx={{
                  width: 240,
                  height: 45,
                  backgroundColor: `${theme.palette.primary.main} !important`,
                  color: "#FFFFFF",
                  fontSize: 12,
                  fontWeight: 550,
                }}
              >
                Submit
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default UserInfoModal;

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { Alert, Stack, useMediaQuery, useTheme } from "@mui/material";
import placesApiKey from "../utils/Google-Places-API-Key";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const PLACES_API_KEY = placesApiKey;

const HomeOrTownOnlyModal = ({
  homeOnlyModalVisible,
  setHomeOnlyModalVisible,
  createMoveRequest,
  thisMoveRequest,
  isLoading,
  setIsLoading,
  setMoveType,
  setOutOfStateMoveModalVisible,
}: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const [address, setAddress] = useState("");
  const [formError, setFormError] = useState("");

  const handleClose = () => setHomeOnlyModalVisible(true);

  const navigateToLocationInfo = async () => {
    if (address !== "") {
      setFormError("");
      await createMoveRequest({
        id: thisMoveRequest.id,
        home_address: address,
      });
    } else {
      setFormError("Please enter your Address.");
    }
  };

  const fetchZipCodeForAddress = async (add: string) => {
    const apiKey = "AIzaSyAk8hBHGztX6M9UX2MdZlRQS3HbdqINzp8";
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${add}&key=${apiKey}`;

    try {
      const response = await axios.get(url);
      const results = response.data.results;

      const zipCode = results[0].address_components?.find((component: any) =>
        component.types.includes("postal_code")
      )?.long_name;
      if (zipCode) {
        return zipCode.toString();
      } else {
        return "";
      }
    } catch (error) {
      throw error;
    }
  };

  const handleAddress = async (add: any) => {
    const zip = await fetchZipCodeForAddress(add.value.description);
    const completeAddress = add.value.description.toString() + ", " + zip;
    setAddress(completeAddress);
  };

  return (
    <>
      <Modal
        open={homeOnlyModalVisible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90vw", sm: "80vw", md: "60vw", lg: "60vw" },
            height: isMobile ? "90vh" : undefined,
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderWidth: 1,
              backgroundColor: "#08123B",
              width: isMobile ? 200 : 330,
              height: 30,
              p: 1,
              borderTopRightRadius: 20,
              borderBottomRightRadius: 20,
            }}
          >
            <Typography fontSize={14} color={"#FFFFFF"}>
              {thisMoveRequest?.move_type}
              <span style={{ margin: "0 16px" }}>&gt;&gt;</span> home or town
              only
            </Typography>
          </Box>

          {isLoading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
              onClick={() => {
                setIsLoading(true);
              }}
            >
              <Stack
                spacing={2}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress color="inherit" />
              </Stack>
            </Backdrop>
          )}

          <Button
            onClick={() => {
              setMoveType("");
              setOutOfStateMoveModalVisible(true);
              setHomeOnlyModalVisible(false);
              // setIsServiceSelectionModal(true);
            }}
            sx={{ marginTop: 1, marginLeft: 1 }}
          >
            <ArrowBackIcon sx={{ color: theme.palette.primary.main }} fontSize="large" />
          </Button>
          <Box sx={{ p: 3 }}>
            <Typography mt={1} mb={1} fontSize={16} fontWeight={700} color={"#262626"}  sx={{textTransform: 'uppercase'  }}>
              What address are you moving from?
            </Typography>
            {/* <Typography mt={1} fontSize={14} color={"#71717A"}>
              As we will pull your home info from our realty platform
            </Typography> */}

            <GooglePlacesAutocomplete
              apiOptions={{
                language: "en",
              }}
              minLengthAutocomplete={1}
              apiKey={PLACES_API_KEY}
              debounce={1000}
              selectProps={{
                styles: {
                  input: (provided) => ({
                    ...provided,
                    color: "#000000",
                    height: "100%",
                    borderRadius: "8px",
                  }),
                  option: (provided) => ({
                    ...provided,
                    color: "#808080",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "#808080",
                  }),
                },
                onChange: handleAddress,
                placeholder: "Enter Address",
              }}
            />

            <Box
              sx={{
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: 2,
                p: 3,
                marginTop: 10,
                backgroundColor: "#22965F0F",
              }}
            >
              <Stack spacing={2}>
                <Typography fontWeight={600} fontSize={12}>
                  Why do we need this information -
                </Typography>
                <Typography color={"#625E5E"} fontSize={12}>
                  This information is required to provide you with an accurate quote and will not be shared or sold. If you don't feel comfortable providing this information, please provide either the zip code and/or the city and state you are moving from.
                </Typography>
              </Stack>
            </Box>
          </Box>

          <Box p={3}>
            {formError && <Alert severity="error">{formError}</Alert>}
          </Box>

          <Stack p={4} alignItems="flex-end">
            <Button
              onClick={() => {
                navigateToLocationInfo();
              }}
              sx={{
                width: 180,
                height: 45,
                backgroundColor: `${theme.palette.primary.main} !important`,
                color: "#FFFFFF",
                fontSize: 12,
                fontWeight: 550,
              }}
            >
              Next →
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default HomeOrTownOnlyModal;

import React from "react";
import { Modal, Box, Button, Typography, Stack, useTheme } from "@mui/material";

// Modal style for centering the video and responsiveness

const VideoPopup = ({ open, setOpen }: any) => {
  const handleClose = () => setOpen(false);
  const theme  = useTheme();
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "600px",
    bgcolor: "background.paper",
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: 24,
    p: 1,
    '@media (max-width: 600px)': {
      width: "90%", // For mobile devices
    },
    '@media (max-width: 900px)': {
      width: "95%", // For tablet devices
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-video-title"
      aria-describedby="modal-video-description"
    >
      <Box sx={style}>
        <Stack direction="row" sx={{ marginBottom: "10px", alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography fontSize={16} fontWeight={600} sx={{ textDecoration: 'underline' }} color={theme.palette.primary.main}>
            Inventory Instructions Guide
          </Typography>
          <Button variant="contained" onClick={handleClose}>
            Close
          </Button>
        </Stack>
        <video width="100%" height="auto" controls autoPlay muted loop>
          <source
            src="https://we-move-production.s3.amazonaws.com/Inventory.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </Box>
    </Modal>
  );
};

export default VideoPopup;

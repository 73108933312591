import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  attachUserWithMoveRequest,
  createThieMoveRequest,
  getAllMoveRequestsByConsumerID,
  getMoveRequestById,
} from "../../redux/actions/move-request";
import { unwrapResult } from "@reduxjs/toolkit";
import { getConsumerById } from "../../redux/actions/consumer";
import ServiceSelectionModal from "../modals/serviceSelectionModal";
import OutOfStateMoveModal from "../modals/outOfStateMoveModal";
import ApartmentOnlyModal from "../modals/apartmentOnlyModal";
import ComboApartmentStorageModal from "../modals/comboApartmentStorageModal";
import HomeStorageCombo from "../modals/homeStorageCombo";
import HomeOrTownOnlyModal from "../modals/homeOrTownOnlyModal";
import StorageOnlyModal from "../modals/storageOnlyModal";
import MoveRequestDetail from "../moveRequestDetail/moveRequestDetail";
import { LandingPage } from "../../pages/landingPage/landingPage";

const ConsumerDashboard = ({ consumerCanonicalId }: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [url, setUrl] = useState(process.env.REACT_APP_URL || "")
  useEffect(()=>{
    console.log(url,'-----')
    if(url==='https://staging.wemoveai.com'){
      console.log('hereeee')
    }
  },[url])
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [consumer, setConsumer] = useState<any>();
  const [allMoveRequests, setAllMoveRequests] = useState<any>([]);
  const [thisMoveRequest, setThisMoveRequest] = useState<any>(allMoveRequests[0]?allMoveRequests[0]:null);

  const [moveType, setMoveType] = useState("");

  const [isShowAllMoveRequests, setIsShowAllMoveRequests] = useState(true);
  const [isMoveRequestDetail, setIsMoveRequestDetail] = useState<any>(null);

  const [isServiceSelectionModal, setIsServiceSelectionModal] = useState(false);
  const [isApartmenOnlyModal, setIsApartmenOnlyModal] = useState(false);
  const [isHomeStorageComboModal, setIsHomeStorageComboModal] = useState(false);
  const [homeOnlyModalVisible, setHomeOnlyModalVisible] = useState(false);
  const [storageOnlyModalVisible, setStorageOnlyModalVisible] = useState(false);
  const [outOfStateMoveModalVisible, setOutOfStateMoveModalVisible] =
    useState(false);
    const [allowNavigation, setAllowNavigation] = useState(false);
    // useEffect(() => {
    //   const handlePopState = () => {
    //     navigate(0);  // This will reload the current page
    //   };
  
    //   window.history.pushState(null, document.title, window.location.href);
    //   window.history.pushState(null, document.title, window.location.href);
  
    //   window.addEventListener('popstate', handlePopState);
  
    //   return () => {
    //     window.removeEventListener('popstate', handlePopState);
    //   };
    // }, [navigate]);
  const [isBackTrue, setIsBackTrue] = useState(false);
  const [
    comboApartmentStorageModalVisible,
    setComboApartmentStorageModalVisible,
  ] = useState(false);

  const fetchAllMoveReuqests = () => {
    dispatch<any>(getAllMoveRequestsByConsumerID(consumerCanonicalId))
      .then(unwrapResult)
      .then((moveRequests: any) => {
        console.log(moveRequests,'--->alllll')
        if(url==='https://production.wemoveai.com'||url==='https://turbotenant.wemoveai.com'||url==='https://sold.wemoveai.com'||url==='https://movebees.wemoveai.com'||url==='https://kruppmoving.wemoveai.com'||url==='https://summitmoving.wemoveai.com'){

          if(moveRequests.length!==0){
              setThisMoveRequest(moveRequests[0])
              if(moveRequests[0].location_type!==null){
                  setMoveType(moveRequests[0].location_type)
                }
              }
              setAllMoveRequests([...allMoveRequests, moveRequests[0]]);
            }else if(url === 'https://staging.wemoveai.com'){
              setAllMoveRequests(moveRequests)
            }
      });
  };

  const fetchThisConsumer = () => {
    dispatch<any>(getConsumerById(consumerCanonicalId))
      .then(unwrapResult)
      .then((consumer: any) => {
        setConsumer(consumer);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const checkFromAddress = (moveReq: any) => {
    if (moveReq.home_address !== null) {
      return moveReq.home_address;
    }
    if (moveReq.apartment !== null) {
      return moveReq.apartment?.apt_address;
    }
    if (moveReq.storage !== null) {
      return moveReq.storage?.address;
    }
    if (moveReq.combo_home_storage !== null) {
      return (
        moveReq.combo_home_storage?.home_address.toString() +
        "/" +
        moveReq.combo_home_storage.storage?.address.toString()
      );
    }
    if (moveReq.combo_apartment_storage !== null) {
      return (
        moveReq.combo_apartment_storage?.apartment?.apt_address.toString() +
        "/" +
        moveReq.combo_apartment_storage?.storage?.address.toString()
      );
    }
  };

  const navigateToLastLeft = (moveRequest: any) => {
    setIsLoading(true);
    setTimeout(()=>{
      setIsLoading(false)
      if (moveRequest.location_type === null) {
        setThisMoveRequest(moveRequest)
        setOutOfStateMoveModalVisible(true);
      } else {
        if (moveRequest.location_type !== null && moveRequest.location_type === "home-only" && moveRequest.home_address===null) {
          setOutOfStateMoveModalVisible(false);
          setThisMoveRequest(moveRequest)
          setHomeOnlyModalVisible(true);
        } else if (moveRequest.location_type !== null && moveRequest.location_type === "storage-only" && moveRequest.storage===null) {
          setOutOfStateMoveModalVisible(false);
          setThisMoveRequest(moveRequest)
          setStorageOnlyModalVisible(true);
        } else if (moveRequest.location_type !== null && moveRequest.location_type === "apartment-only" &&moveRequest.apartment===null) {
          setOutOfStateMoveModalVisible(false);
          setThisMoveRequest(moveRequest)
          setIsApartmenOnlyModal(true);
        } else if (moveRequest.location_type !== null && moveRequest.location_type === "combo-home-storage" &&moveRequest.combo_home_storage===null) {
          setOutOfStateMoveModalVisible(false);
          setThisMoveRequest(moveRequest)
          setIsHomeStorageComboModal(true);
        } else if (moveRequest.location_type !== null && moveRequest.location_type === "combo-apartment-storage" &&moveRequest.combo_apartment_storage===null) {
          setOutOfStateMoveModalVisible(false);
          setThisMoveRequest(moveRequest)
          setComboApartmentStorageModalVisible(true);
        } else {
          if (moveRequest.location_type !== 'storage-only' && moveRequest.roomDetails.length === 0) {
            navigate(`/inventory-guidelines/${moveRequest.canonical_id}`);
          } else {
            if (!moveRequest.delivery_details) {
              navigate(`/AddressInfo/${moveRequest.canonical_id}`);
            } else {
              if (moveRequest.delivery_details?.delivery_addresses?.length === 0) {
                navigate(`/AddressInfo/${moveRequest.canonical_id}`);
              } else {
                if (moveRequest.delivery_details?.delivery_addresses[0].delivery_location_type === null) {
                  navigate(`/DeliveryLocationInfo/${moveRequest.canonical_id}`);
                } else {
                  if (moveRequest.pickup_date_from === null && moveRequest.pickup_date_to === null) {
                    navigate(`/PickupDate/${moveRequest.canonical_id}`);
                  } else {
                    if (moveRequest.delivery_details.open_location === null) {
                      navigate(`/TruckInfo/${moveRequest.canonical_id}`);
                    } else {
                      if ((moveRequest.mover_category === null || moveRequest.total_bedrooms === null) && moveRequest.location_type !== 'storage-only') {
                        navigate(`/boxing-info/${moveRequest.canonical_id}`);
                      } else {
                        if (moveRequest.total_cubic_feet === null) {
                          navigate(`/itemsInfo/${moveRequest.canonical_id}`);
                        } else {
                          if (moveRequest.delivery_details?.packagaing_required === null && checkTenant()===true) {
                            navigate(`/packages/${moveRequest.canonical_id}`);
                          } else {
                            if(moveRequest.user===null  && checkTenant()===true){
                              console.log(moveRequest,'------>>>>>>>>>>>>>>>>>')
                              navigate(`/user-verification/${moveRequest.canonical_id}`)
                            }else{
                              if(moveRequest.user.email_verified===false  && checkTenant()===true){
                                navigate(`/login`);
                              }else{
                                if (moveRequest.initial_deposit === null || moveRequest.contract_signed === null  && checkTenant()===true) {
                                  navigate(`/move-summary/${moveRequest.canonical_id}`);
                                } else {
                                  if (moveRequest.contract_signed === false  && checkTenant()===true) {
                                    navigate(`/contract/${moveRequest.canonical_id}`);
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },1000)
  
  };
  

  const createMoveRequest = (body: any) => {
    setIsLoading(true);
    const params = {
      // consumerId: consumer.id,
      body: body,
    };
    console.log("params in createMoveRequest=>", params);
    dispatch<any>(createThieMoveRequest(params))
      .then(unwrapResult)
      .then((res: any) => {
        const moveRequestObject = JSON.stringify(res);
        localStorage.setItem("current-move-request", moveRequestObject);
        localStorage.setItem('move-request-id',res.canonical_id)
        console.log(res.user,'---CREATED NOQWWW->>>>>>')
        if(localStorage.getItem('user-object')&&res.user===null){
          const userObject = localStorage.getItem('user-object')
          if(userObject){
            const user = JSON.parse(userObject)
            console.log('NOPEEEEEEEE',user)
            const newParams = {
              moveRequestId: res.id,
              userId: user.id
            }
            dispatch<any>(attachUserWithMoveRequest(newParams)).then(unwrapResult).then((move:any)=>{console.log('MOVE ATTACHED TO USER')})
          }
        }
        setThisMoveRequest(res);
        setIsLoading(false);
        if (isServiceSelectionModal === true) {
          setOutOfStateMoveModalVisible(true);
          setIsServiceSelectionModal(false);
        } else {
          if (
            outOfStateMoveModalVisible === true &&
            moveType === "apartment-only"
          ) {
            setOutOfStateMoveModalVisible(false);
            setIsApartmenOnlyModal(true);
          } else {
            if (
              outOfStateMoveModalVisible === true &&
              moveType === "combo-apartment-storage"
            ) {
              setOutOfStateMoveModalVisible(false);
              setComboApartmentStorageModalVisible(true);
            } else {
              if (
                outOfStateMoveModalVisible === true &&
                moveType === "combo-home-storage"
              ) {
                setOutOfStateMoveModalVisible(false);
                setIsHomeStorageComboModal(true);
              } else {
                if (
                  outOfStateMoveModalVisible === true &&
                  moveType === "home-only"
                ) {
                  setOutOfStateMoveModalVisible(false);
                  setHomeOnlyModalVisible(true);
                } else {
                  if (
                    outOfStateMoveModalVisible === true &&
                    moveType === "storage-only"
                  ) {
                    setOutOfStateMoveModalVisible(false);
                    setStorageOnlyModalVisible(true);
                  } else {
                    if (res.location_type === "storage-only") {
                      navigate(`/addressInfo/${thisMoveRequest.canonical_id}`);
                    } else {
                      navigate(
                        `/inventory-guidelines/${thisMoveRequest.canonical_id}`
                      );
                    }
                  }
                }
              }
            }
          }
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };

  const handleMoveRequestDetail = (moveRequest: any) => {
    console.log("move req for details=>", moveRequest)
    setIsShowAllMoveRequests(false);
    setIsMoveRequestDetail(moveRequest);
  };
  const fetchMoveRequestById = (moveRequestId: any) => {
    dispatch<any>(getMoveRequestById(moveRequestId))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        console.log(thisMoveRequest.user,'--user')
        const alreadyExists = allMoveRequests.some(
          (request:any) => request.id === thisMoveRequest.id
        );
        if (!alreadyExists) {
          setAllMoveRequests([...allMoveRequests, thisMoveRequest]);
          setThisMoveRequest(thisMoveRequest)
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    if(consumerCanonicalId !== undefined)
    {
      console.log("consumerid in dash=>",consumerCanonicalId)
      fetchThisConsumer();

      if(allMoveRequests.length===0){
        console.log('here im in---')
        fetchAllMoveReuqests();
      }
    }else{
      if(localStorage.getItem('move-request-id')){
        console.log("move req in local=>",localStorage.getItem("move-request-id"))
        const moveId = localStorage.getItem("move-request-id")
        fetchMoveRequestById(moveId);
      }
    }

    console.log(localStorage.getItem("user-origin"))

    setTimeout(() => {
      setIsLoading(false);
    }, 1300);
  }, []);

  useEffect(()=>{
    if(url==='https://production.wemoveai.com'||url==='https://turbotenant.wemoveai.com'||url==='https://sold.wemoveai.com'||url==='https://movebees.wemoveai.com'||url==='https://kruppmoving.wemoveai.com'||url==='https://summitmoving.wemoveai.com'){
      if(thisMoveRequest!==null){
        handleMoveRequestDetail(thisMoveRequest)
      }
    }
  },[thisMoveRequest])
  const executeLoader = () => {
    return(
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
          onClick={() => {
            setIsLoading(true);
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
    )
  }
  const checkTenant = () => {
    if(process.env.REACT_APP_TENANT==='wemove'||process.env.REACT_APP_TENANT==='turbotenant'){
      return true;
    }else{
      return false
    }
  }
  return (
    <>
      {isMoveRequestDetail !== null && (
        <MoveRequestDetail
          moveRequest={isMoveRequestDetail}
          setIsMoveRequestDetail={setIsMoveRequestDetail}
          setIsShowAllMoveRequests={setIsShowAllMoveRequests}
          setMoveRequest={setIsMoveRequestDetail}
          navigateToLastLeft={navigateToLastLeft}
          thisMoveRequest={thisMoveRequest}
          executeLoader={executeLoader}
          isLoading={isLoading}
          checkTenant={checkTenant}
          url={url}
        />
      )}
      {isServiceSelectionModal && (
        <ServiceSelectionModal
          isServiceSelectionModal={isServiceSelectionModal}
          setIsServiceSelectionModal={setIsServiceSelectionModal}
          createMoveRequest={createMoveRequest}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          thisMoveRequest={thisMoveRequest}
        />
      )}
      {outOfStateMoveModalVisible && (
        <OutOfStateMoveModal
          outOfStateMoveModalVisible={outOfStateMoveModalVisible}
          setOutOfStateMoveModalVisible={setOutOfStateMoveModalVisible}
          createMoveRequest={createMoveRequest}
          thisMoveRequest={thisMoveRequest}
          moveType={moveType}
          setMoveType={setMoveType}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setIsServiceSelectionModal={setIsServiceSelectionModal}
        />
      )}
      {isApartmenOnlyModal && (
        <ApartmentOnlyModal
          isApartmenOnlyModal={isApartmenOnlyModal}
          setIsApartmenOnlyModal={setIsApartmenOnlyModal}
          createMoveRequest={createMoveRequest}
          thisMoveRequest={thisMoveRequest}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setMoveType={setMoveType}
          setOutOfStateMoveModalVisible={setOutOfStateMoveModalVisible}
        />
      )}
      {comboApartmentStorageModalVisible && (
        <ComboApartmentStorageModal
          comboApartmentStorageModalVisible={comboApartmentStorageModalVisible}
          setComboApartmentStorageModalVisible={
            setComboApartmentStorageModalVisible
          }
          createMoveRequest={createMoveRequest}
          thisMoveRequest={thisMoveRequest}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setMoveType={setMoveType}
          setOutOfStateMoveModalVisible={setOutOfStateMoveModalVisible}
        />
      )}
      {isHomeStorageComboModal && (
        <HomeStorageCombo
          isHomeStorageComboModal={isHomeStorageComboModal}
          setIsHomeStorageComboModal={setIsHomeStorageComboModal}
          createMoveRequest={createMoveRequest}
          thisMoveRequest={thisMoveRequest}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setMoveType={setMoveType}
          setOutOfStateMoveModalVisible={setOutOfStateMoveModalVisible}
        />
      )}
      {homeOnlyModalVisible && (
        <HomeOrTownOnlyModal
          homeOnlyModalVisible={homeOnlyModalVisible}
          setHomeOnlyModalVisible={setHomeOnlyModalVisible}
          createMoveRequest={createMoveRequest}
          thisMoveRequest={thisMoveRequest}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setOutOfStateMoveModalVisible={setOutOfStateMoveModalVisible}
          setMoveType={setMoveType}
        />
      )}
      {storageOnlyModalVisible && (
        <StorageOnlyModal
          storageOnlyModalVisible={storageOnlyModalVisible}
          setStorageOnlyModalVisible={setStorageOnlyModalVisible}
          createMoveRequest={createMoveRequest}
          thisMoveRequest={thisMoveRequest}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setMoveType={setMoveType}
          setOutOfStateMoveModalVisible={setOutOfStateMoveModalVisible}
        />
      )}
      {isShowAllMoveRequests && (
        <Grid mt={2} item xs={12} md={6}>
          <LandingPage
          setIsServiceSelectionModal={setIsServiceSelectionModal}
          setThisMoveRequest={setThisMoveRequest}
          />
          {/* <Stack
            display={"flex"}
            justifyContent={"center"}
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            marginBottom={2}
            marginX={1}
          >
            <Typography fontWeight={500} fontSize={16}>
              ARE YOU READY TO MAKE A MOVE? LET'S GO!
            </Typography>
            <Button
              onClick={() => {
                setIsServiceSelectionModal(true);
              }}
              sx={{
                borderColor: theme.palette.primary.main,
                bgcolor: theme.palette.primary.main,
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                  color: theme.palette.primary.main,
                },
              }}
              variant="outlined"
            >
              Start a new Move
            </Button>
          </Stack> */}
          <Divider />
          {url==='https://staging.wemoveai.com'&&(

            <Stack>
            <Typography
              sx={{ textDecoration: "underline" }}
              fontWeight={"600"}
              fontSize={20}
              mt={1}
              mb={1}
              alignSelf={"center"}
              >
              MOVE REQUESTS
            </Typography>

            <TableContainer
              sx={{ width: isMobile ? "85vw" : "100%" }}
              component={Paper}
              >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{ backgroundColor: theme.palette.primary.main }}>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "600", fontSize: 12 }}
                      >
                      Move Order #
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "600", fontSize: 12 }}
                      >
                      Move Details
                    </TableCell>
                   
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "600", fontSize: 12 }}
                      >
                      Pick-up Date
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "600", fontSize: 12 }}
                      >
                      Move Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allMoveRequests.map((thisRequest: any) => (
                    <TableRow
                    key={thisRequest.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={{ fontWeight: "600" }} align="center">
                        {thisRequest.move_order_number}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "600" }} align="center">
                        <Button
                          onClick={() => {
                            handleMoveRequestDetail(thisRequest);
                          }}
                          >
                          View Details
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        {thisRequest.pickup_date_from
                          ? new Date(
                            thisRequest.pickup_date_from
                          ).toLocaleDateString("en-US", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })
                          : "NaN"}{" "}
                        -{" "}
                        {thisRequest.pickup_date_to
                          ? new Date(
                            thisRequest.pickup_date_to
                          ).toLocaleDateString("en-US", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })
                          : "NaN"}
                      </TableCell>
                     
                     
                      <TableCell sx={{ fontWeight: "600", fontSize: 12 }}>                  
                      {thisRequest.move_type===null || thisRequest.location_type === null || thisRequest.delivery_details === null ||
                        thisRequest.delivery_details?.delivery_addresses
                          ?.length === 0 ||
                        (thisRequest.delivery_details?.packagaing_required ===
                          null && checkTenant()===true) ||
                        thisRequest.pickup_date_from === null ||
                        thisRequest.pickup_date_to === null ||
                        thisRequest.delivery_details?.open_location === null ||
                        thisRequest.boxable_items_volume  === null || thisRequest.no_of_boxes_for_boxable_items===null || ((thisRequest.initial_deposit===null|| thisRequest.contract_signed===null || thisRequest.contract_signed === false)  && checkTenant()===true)? (
                          <Button
                          onClick={() => {
                            navigateToLastLeft(thisRequest);
                          }}
                          sx={{
                            borderColor: theme.palette.primary.main,
                            bgcolor: theme.palette.primary.main,
                            color: "#FFFFFF",
                            fontSize: 10,
                            "&:hover": {
                              backgroundColor: "#000000",
                              color: "#FFFFFF",
                              fontSize: 10,
                            },
                          }}
                          variant="outlined"
                          >
                            Complete Move
                          </Button>
                        ) : (
                          <Typography fontSize={12}>Completed</Typography>
                        )}     
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
                )}
          {isLoading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
              onClick={() => {
                setIsLoading(true);
              }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Grid>
      )}
    </>
  );
};

export default ConsumerDashboard;

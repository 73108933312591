import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
  useTheme,
} from "@mui/material";
import AddItemModal from "../../components/modals/addItemModal";
import { useEffect, useState } from "react";
import Subtract from "@mui/icons-material/Remove";
import EditIcon from "@mui/icons-material/Create";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getMoveRequestById,
  updateMoveRequestById,
} from "../../redux/actions/move-request";
import { unwrapResult } from "@reduxjs/toolkit";
import InputAlertModal from "../../components/modals/inputAlertModal";
import ProgressBar from "../../components/progressBar/progressBar";
import NavBar from "../../components/navbar/navBar";
import { getInventoryVolume, updateRoomDetails } from "../../redux/actions/room-details";
import PackagingServiceModal from "../../components/modals/packagingServiceModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import UploadMoreImagesModal from "../../components/modals/uploadMoreImagesModal";
import { io } from "socket.io-client";
import ReactGA from "react-ga4";
import ContactSupport from "../../components/contactSupport";
import FinalizeFlowModal from "../../components/modals/finalizeFlowModal";
import UserInfoModal from "../../components/modals/userInfoModal";
import pushMoveDataToCRM from "../../assets/utils/pushMoveData";

const iconButtonStyle = {
  width: "15px",
  height: "15px",
  color: "white",
  backgroundColor: "#5858E0",
  "&:hover": {
    backgroundColor: "#5858E0",
    opacity: "0.9",
  },
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#08123B",
    color: "#FFFFFF",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    width: "fit-content",
    fontSize: 15,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontWeight: "500 !important",
    backgroundColor: "#FDFBFB",
    border: "1px solid #FFFFFF !important",
  },
}));

const ItemsInfo = () => {
  const theme = useTheme();

  const [url, setUrl] = useState(process.env.REACT_APP_URL || "");
  const addButton = {
    width: 80,
    height: 30,
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: 600,
  };
  const blueButton = {
    width: 150,
    height: 50,
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "#FFFFFF",
    fontSize: 12,
    fontWeight: 550,
  };
  if (url === "https://stagingapi.wemoveai.com/") {
    ReactGA.send({
      hitType: "pageview",
      page: "/itemsInfo/:moveRequestId",
      title: "Item info Staging",
    });
  }

  if (url === "https://productionapi.wemoveai.com/") {
    ReactGA.send({
      hitType: "pageview",
      page: "/itemsInfo/:moveRequestId",
      title: "Item info Production",
    });
  }

  if (url === "https://turbotenantapi.wemoveai.com/") {
    ReactGA.send({
      hitType: "pageview",
      page: "/itemsInfo/:moveRequestId",
      title: "Item info Turbotenant",
    });
  }

  if (url === "https://soldapi.wemoveai.com/") {
    ReactGA.send({
      hitType: "pageview",
      page: "/itemsInfo/:moveRequestId",
      title: "Item info Sold",
    });
  }

  const dispatch = useDispatch();
  const thisUrl = process.env.REACT_APP_API_URL || "";
  const { moveRequestId } = useParams();

  const [canonicalId, setCanonicalId] = useState("");
  const [moveRequest, setMoveRequest] = useState<any>(null);
  const [thisMoveRequestId, setThisMoveRequestId] = useState(null);

  const [isAddItemModal, setIsAddItemModal] = useState(false);
  const [isInputAlertModal, setIsInputAlertModal] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isPackaginServiceModal, setIsPackaginServiceModal] = useState(false);
  const [uploadMoreImagesModalVisile, setUploadMoreImagesModalVisile] =
    useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const [actionType, setActionType] = useState("");
  const [editItemInfo, setEditItemInfo] = useState(null);
  const [itemIndex, setItemIndex] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [roomDetailsArray, setRoomDetailsArray] = useState<any>([]);
  const [itemsCount, setItemsCount] = useState(null);
  const [imageId, setImageId] = useState<any>(null);
  // const handleAddItemModal = (roomId: any, item?: any, index?: any) => {
  //   if (item) {
  //     setEditItemInfo(item);
  //     setItemIndex(index);
  //   } else {
  //     setEditItemInfo(null);
  //     setItemIndex(null);
  //   }
  //   setRoomId(roomId);
  //   setIsAddItemModal(true);
  // };
  const handleAddItemModal = (
    roomId: any,
    imageId: any,
    item?: any,
    index?: any
  ) => {
    if (item) {
      setEditItemInfo(item);
      setItemIndex(index);
    } else {
      setEditItemInfo(null);
      setItemIndex(null);
    }
    setRoomId(roomId);
    setImageId(imageId);
    setIsAddItemModal(true);
  };

  // const handleSubtract = (roomid: any, itemIndex: number) => {
  //   if (
  //     editItemInfo &&
  //     editItemInfo === roomDetailsArray.items.item[itemIndex]
  //   ) {
  //     setEditItemInfo(null);
  //   }
  //   setRoomDetailsArray((prevRoomDetailsArray: any) => {
  //     return prevRoomDetailsArray.map((room: any) => {
  //       if (room.id === roomid) {
  //         const updatedItems = room.items.filter(
  //           (_: any, index: any) => index !== itemIndex
  //         );
  //         return { ...room, items: updatedItems };
  //       }
  //       return room;
  //     });
  //   });
  // };
  const handleSubtract = (roomId: any, imageId: any, itemIndex: number) => {
    if (
      editItemInfo &&
      editItemInfo ===
        roomDetailsArray
          .find((room: any) => room.id === roomId)
          .room_images.find((image: any) => image.id === imageId).items[
          itemIndex
        ]
    ) {
      setEditItemInfo(null);
    }

    setRoomDetailsArray((prevRoomDetailsArray: any) => {
      return prevRoomDetailsArray.map((room: any) => {
        if (room.id === roomId) {
          const updatedRoomImages = room.room_images.map((image: any) => {
            if (image.id === imageId) {
              const updatedItems = image.items.filter(
                (_: any, index: any) => index !== itemIndex
              );
              return { ...image, items: updatedItems };
            }
            return image;
          });
          return { ...room, room_images: updatedRoomImages };
        }
        return room;
      });
    });
  };

  const [numberOfBoxexRequired, setNumberOfBoxexRequired] = useState<any>(null);
  const [moveDistance, setMoveDistance] = useState<any>(0.0);
  const fetchMoveRequestById = (moveRequestId: any) => {
    dispatch<any>(getMoveRequestById(moveRequestId))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        if (thisMoveRequest.move_distance !== null) {
          console.log(thisMoveRequest.move_distance,'->>Distance')
          setMoveDistance(Number(thisMoveRequest.move_distance));
        } else {
          setMoveDistance(thisMoveRequest.move_distance);
        }
        getVolume()
        setNumberOfBoxexRequired(
          Number(thisMoveRequest.no_of_boxes_for_boxable_items)
        );
        const filteredRoomDetails = thisMoveRequest.roomDetails
          .filter(
            (room: any) => room.room_images && room.room_images.length > 0
          )
          .map((room: any) => ({
            ...room,
            room_images: room.room_images.map((image: any) => ({
              ...image,
              items: image.items.map((item: any) => ({
                item_name: item.item_name || "",
                item_width: item.item_width || "",
                item_height: item.item_height || "",
                item_length: item.item_length || "",
                is_bulky: item.is_bulky || false,
              })),
            })),
          }));

        // const filteredRoomDetails = thisMoveRequest.roomDetails
        //   .filter((room: any) => room.items && room.items.length > 0)
        //   .map((room: any) => ({
        //     ...room,
        //     items: room.items.map((item: any) => ({
        //       item_name: item.item_name || "",
        //       item_width: item.item_width || "",
        //       item_height: item.item_height || "",
        //       item_length: item.item_length || "",
        //     })),
        //   }));

        setMoveRequest(thisMoveRequest);
        setRoomDetailsArray(filteredRoomDetails);
        setThisMoveRequestId(thisMoveRequest.id);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
const sendDataToCrm = async() => {
  const tenant = process.env.REACT_APP_TENANT||'';
  await pushMoveDataToCRM(moveRequest,tenant)
  navigate(`/booked-move/${moveRequestId}`)
}
  const updateMove = async (params: any) => {
    setIsPageLoading(true);
    const finalParams = {
      id: thisMoveRequestId,
      ...params,
    };

    dispatch<any>(updateMoveRequestById(finalParams))
      .then(unwrapResult)
      .then((res: any) => {
        setMoveRequest(res);
      })
      .catch((err: any) => {
        console.log(err);
        setTimeout(() => {
          setIsPageLoading(false);
        }, 1300);
      });
  };
  const updateThisMoveRequest = async () => {
    localStorage.removeItem("action-type");
    setIsPageLoading(true);
    await Promise.all(
      roomDetailsArray.map(async (room: any) => {
        room.room_images.map(async (img: any) => {
          const body = {
            roomDetailId: room.id,
            roomImageId: img.id,
            items: img.items,
          };
          await dispatch<any>(updateRoomDetails(body))
            .then(unwrapResult)
            .then((res: any) => {
              console.log("Room updated", res);
            })
            .catch((err: any) => {
              console.log(err);
            });
        });
      })
    );
    setCanonicalId(moveRequest.canonical_id);
    if (moveRequest.location_type !== "storage-only") {
      const params = {
        id: thisMoveRequestId,
        no_of_boxes_for_boxable_items: numberOfBoxexRequired,
        boxable_items_volume: Number(numberOfBoxexRequired) * 3,
      };
      dispatch<any>(updateMoveRequestById(params))
        .then(unwrapResult)
        .then(async(res: any) => {
          console.log(res.total_cubic_feet,'-----')
          setTotalCubicFeet((total)=> total + res.total_cubic_feet)
          await getCubicFeetAndCalculatePrice()
          setTimeout(() => {
            setIsPageLoading(false);
            console.log(res.mover_email,'--',res.mover_name)
            if (process.env.REACT_APP_URL === 'https://production.wemoveai.com' ||
              process.env.REACT_APP_URL === 'https://sold.wemoveai.com' ||
              process.env.REACT_APP_URL === 'https://turbotenant.wemoveai.com') {
              
              // Uncomment and implement the check if necessary
              // if (moveRequest.mover_name === null || moveRequest.mover_email === null) {
              //     // Handle the case where mover name or email is null
              // }
              
              setIsPackaginServiceModal(true);
          } else {
              if (process.env.REACT_APP_URL === 'https://staging.wemoveai.com') {
                  setIsFinalizeFlowModal(true);
              } else if (
                  process.env.REACT_APP_URL === 'https://movebees.wemoveai.com' ||
                  process.env.REACT_APP_URL === 'https://kruppmoving.wemoveai.com' ||
                  process.env.REACT_APP_URL === 'https://summitmoving.wemoveai.com'
              ) {
                  
                  if (moveRequest.mover_name === null || moveRequest.mover_email === null) {
                      setIsUserInfoModal(true);
                  } else {
                      sendDataToCrm();
                  }
              }
          }
          }, 1500);
        })
        .catch((err: any) => {
          console.log(err);
          setIsPageLoading(false);
        });
    } else {
      await getCubicFeetAndCalculatePrice()
      setTimeout(() => {
        
        setIsPageLoading(false);
        if (process.env.REACT_APP_URL === 'https://production.wemoveai.com' ||
          process.env.REACT_APP_URL === 'https://sold.wemoveai.com' ||
          process.env.REACT_APP_URL === 'https://turbotenant.wemoveai.com') {
          
          // Uncomment and implement the check if necessary
          // if (moveRequest.mover_name === null || moveRequest.mover_email === null) {
          //     // Handle the case where mover name or email is null
          // }
          
          setIsPackaginServiceModal(true);
      } else {
          if (process.env.REACT_APP_URL === 'https://staging.wemoveai.com') {
              setIsFinalizeFlowModal(true);
          } else if (
              process.env.REACT_APP_URL === 'https://movebees.wemoveai.com' ||
              process.env.REACT_APP_URL === 'https://kruppmoving.wemoveai.com' ||
              process.env.REACT_APP_URL === 'https://summitmoving.wemoveai.com'
          ) {
              
              if (moveRequest.mover_name === null || moveRequest.mover_email === null) {
                  setIsUserInfoModal(true);
              } else {
                  sendDataToCrm();
              }
          }
      }
      
        // setIsPackaginServiceModal(true);
      }, 1500);
    }
  };
  const [isUserInfoModal, setIsUserInfoModal] = useState(false)
  const getCubicFeetAndCalculatePrice = async() => {
    const move_request_id = moveRequestId || "";
    dispatch<any>(getInventoryVolume(move_request_id))
      .then(unwrapResult)
      .then(async (res: any) => {
        setMoveRequest(res)
        setTotalCubicFeet((totalcf)=> totalcf+res.total_cubic_feet)
    if (
      totalCubicFeet !== 0.0 &&
      moveDistance !== 0.0 &&
      moveDistance !== null
    ) {
      await pricingModel(moveDistance, res, res.total_cubic_feet);
    }
    if (moveDistance === null) {
      setShowDistanceModal(true);
    }
      });
  };
  const getVolume = () => {
    const move_request_id = moveRequestId || "";
    dispatch<any>(getInventoryVolume(move_request_id))
      .then(unwrapResult)
      .then(async (res: any) => {
        setMoveRequest(res)
        setTotalCubicFeet((totalcf)=> totalcf+res.total_cubic_feet)
      });
  }

  // useEffect(() => {
  //   const totalItemsCount = roomDetailsArray.reduce((total: any, room: any) => {
  //     return total + (room.items ? room.items.length : 0);
  //   }, 0);
  //   setItemsCount(totalItemsCount);
  // }, [roomDetailsArray]);
  useEffect(() => {
    console.log(roomDetailsArray, "---->");
    const totalItemsCount = roomDetailsArray.reduce((total: any, room: any) => {
      return (
        total +
        room.room_images.reduce((imageTotal: any, image: any) => {
          return imageTotal + (image.items ? image.items.length : 0);
        }, 0)
      );
    }, 0);
    setItemsCount(totalItemsCount);
  }, [roomDetailsArray]);

  const handleAddMoreImages = () => {
    setActionType("add-item");
    setUploadMoreImagesModalVisile(true);
  };

  useEffect(() => {
    const socket = io(thisUrl);

    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    socket.on("connect_error", (error) => {
      console.error("WebSocket connection error:", error);
    });

    socket.on("notifyToRedirect", (requestData: any) => {
      setShouldRedirect(requestData);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (shouldRedirect === true) {
      window.location.reload();
    }
  }, [shouldRedirect]);

  useEffect(() => {
    fetchMoveRequestById(moveRequestId);
    setIsInputAlertModal(true);
    if (localStorage.getItem("action-type")) {
      setShowSuccessMsg(true);
    }
  }, []);
  const navigate = useNavigate();

  const [priceOfItemsOnly, setPriceOfItemsOnly] = useState(0.0);
  const localMoveFormula = (move: any) => {
    let numberOfMovers = 0;
    if (move.total_cubic_feet >= 1 && move.total_cubic_feet <= 700) {
      numberOfMovers = 2;
    } else if (move.total_cubic_feet >= 701 && move.total_cubic_feet <= 1300) {
      numberOfMovers = 3;
    } else if (move.total_cubic_feet >= 1301 && move.total_cubic_feet <= 1900) {
      numberOfMovers = 4;
    } else if (move.total_cubic_feet >= 1901 && move.total_cubic_feet <= 2500) {
      numberOfMovers = 5;
    } else if (move.total_cubic_feet > 2500) {
      numberOfMovers = 6;
    }
    let totalCubicFeetOnJob = move.total_cubic_feet * 2; //load and unload
    let totalManHours = totalCubicFeetOnJob / 104;
    let approximateHours = totalManHours / numberOfMovers;
    let travelTime = 1;
    if (move.move_distance > 50) {
      travelTime = 2;
    }
    let mileageFee = 1 * move.move_distance;
    const breakTime = 0.5;
    let totalEstimatedTime = approximateHours + travelTime + breakTime;
    let costPerHour = 0;
    if (numberOfMovers === 2) {
      costPerHour = 180;
    } else if (numberOfMovers === 3) {
      costPerHour = 230;
    } else if (numberOfMovers === 4) {
      costPerHour = 280;
    } else if (numberOfMovers === 5) {
      costPerHour = 350;
    } else if (numberOfMovers === 6) {
      costPerHour = 410;
    }
    let totalMoveCost = totalEstimatedTime * costPerHour + mileageFee;
    return totalMoveCost;
  };
  const [pickupCharges, setPickupCharges] = useState(0);

  const chargesForPickupLocation = (move: any) => {
    let finalPickupCharges = 0;

    if (move.apartment !== null) {
      if (move.apartment.is_elevator_available === true) {
        setPickupCharges((charges) => charges + 75);
        finalPickupCharges += 75;
        return finalPickupCharges;
      } else {
        if (
          move.apartment.is_elevator_available === false &&
          move.apartment.floor_no > 2
        ) {
          const additionalFloors = Number(move.apartment.floor_no) - 2;
          const stairCharges = additionalFloors * 75;

          setPickupCharges((charges) => charges + stairCharges);
          finalPickupCharges += stairCharges;
          return finalPickupCharges;
        }
      }
    }

    if (move.storage !== null) {
      if (move.storage.is_elevator_available === true) {
        finalPickupCharges += 75;

        return finalPickupCharges;
      } else {
        if (
          move.storage.is_elevator_available === false &&
          move.storage.are_stairs_present === true
        ) {
          const additionalFloors = Number(move.storage.floor_no) - 2;
          const stairCharges = additionalFloors * 75;

          setPickupCharges((charges) => charges + stairCharges);
          finalPickupCharges += stairCharges;
          return finalPickupCharges;
        }
      }
    }

    if (move.combo_apartment_storage !== null) {
      if (
        move.combo_apartment_storage.apartment.is_elevator_available === true
      ) {
        finalPickupCharges += 75;
      } else {
        if (
          move.combo_apartment_storage.apartment.is_elevator_available ===
            false &&
          move.combo_apartment_storage.apartment.floor_no > 2
        ) {
          const additionalFloors =
            Number(move.combo_apartment_storage.apartment.floor_no) - 2;
          const stairCharges = additionalFloors * 75;

          setPickupCharges((charges) => charges + stairCharges);
          finalPickupCharges += stairCharges;
        }
      }

      if (move.combo_apartment_storage.storage.is_elevator_available === true) {
        finalPickupCharges += 75;
      } else {
        if (
          move.combo_apartment_storage.storage.is_elevator_available ===
            false &&
          move.combo_apartment_storage.storage.are_stairs_present === true
        ) {
          const additionalFloors =
            Number(move.combo_apartment_storage.storage.floor_no) - 2;
          const stairCharges = additionalFloors * 75;

          setPickupCharges((charges) => charges + stairCharges);
          finalPickupCharges += stairCharges;
        }
      }
      return finalPickupCharges;
    }

    if (move.combo_home_storage !== null) {
      if (move.combo_home_storage.storage.is_elevator_available === true) {
        finalPickupCharges += 75;
        return finalPickupCharges;
      } else {
        if (
          move.combo_home_storage.storage.is_elevator_available === false &&
          move.combo_home_storage.storage.are_stairs_present === true
        ) {
          const additionalFloors =
            Number(move.combo_home_storage.storage.floor_no) - 2;
          const stairCharges = additionalFloors * 75;
          setPickupCharges((charges) => charges + stairCharges);
          finalPickupCharges += stairCharges;
          return finalPickupCharges;
        }
      }
    }
    return finalPickupCharges;
  };
  const [totalMovePrice, setTotalMovePrice] = useState(0.0);
  const calculateBulkyFee = async () => {
    let total_bulky_fee = 0;
    moveRequest?.roomDetails.forEach((room: any) => {
      room.room_images?.forEach((image: any) => {
        image.items?.forEach((item: any) => {
          if (item.is_bulky && item.bulky_fee !== 0) {
            total_bulky_fee += item.bulky_fee;
          }
        });
      });
    });
    return total_bulky_fee;
  };
  const [weMoveCommision, setWeMoveCommission] = useState(0.0);
  const [totalCubicFeet, setTotalCubicFeet] = useState(0.0);

  const pricingModel = async (moveDistance: any, moveRequest:any, totalCubicFeet:number) => {
    console.log(moveDistance,'D-->',totalCubicFeet)
    if (Number(moveDistance) > 0 && Number(moveDistance) <= 50) {
      // const price_per_cubic_feet = 1.53;
      const price = localMoveFormula(moveRequest);
      setPriceOfItemsOnly((total) => total + price);

      setTotalMovePrice((thisPrice) => thisPrice + price);
      if (
        moveRequest.delivery_details.delivery_addresses[0]
          .delivery_location_type !== "Home"
      ) {
        if (
          moveRequest.delivery_details.delivery_addresses[0]
            .is_elevator_accessible
        ) {
          // const price_after_delivery_side_elevator = price + 75;
          setTotalMovePrice((total) => total + 75);
        } else {
          if (
            moveRequest.delivery_details.delivery_addresses[0].stiars_present
          ) {
            if (
              moveRequest.delivery_details.delivery_addresses[0].floor_no > 2
            ) {
              const additionalFloors =
                Number(
                  moveRequest.delivery_details.delivery_addresses[0].floor_no
                ) - 2;
              const flight_fee = additionalFloors * 75;
              setTotalMovePrice((total) => total + flight_fee);
            }
          }
        }
      }

      if (
        moveRequest.delivery_details.packagaing_required === true &&
        moveRequest.delivery_details.packaging !== null
      ) {
        setTotalMovePrice(
          (total) =>
            total +
            Number(moveRequest.delivery_details.packaging.packaging_payment)
        );
      }

      if (
        moveRequest.apartment !== null ||
        moveRequest.storage !== null ||
        moveRequest.combo_apartment_storage !== null ||
        moveRequest.combo_home_storage !== null
      ) {
        const charges = chargesForPickupLocation(moveRequest);
        setTotalMovePrice((total) => total + Number(charges));
      }
      const bulky_fee = await calculateBulkyFee();
      setTotalMovePrice((total) => total + bulky_fee);
      setWeMoveCommission((commission) => commission + 0.129 * price);
    }

    if (Number(moveDistance) > 50 && Number(moveDistance) <= 100) {
      // const price_per_cubic_feet = 2.82;
      const price = localMoveFormula(moveRequest);
      setPriceOfItemsOnly((total) => total + price);
      setTotalMovePrice((thisPrice) => thisPrice + price);
      if (
        moveRequest.delivery_details.delivery_addresses[0]
          .delivery_location_type !== "Home"
      ) {
        if (
          moveRequest.delivery_details.delivery_addresses[0]
            .is_elevator_accessible
        ) {
          // const price_after_delivery_side_elevator = price + 75;
          setTotalMovePrice((total) => total + 75);
        } else {
          if (
            moveRequest.delivery_details.delivery_addresses[0].stiars_present
          ) {
            if (
              moveRequest.delivery_details.delivery_addresses[0].floor_no > 2
            ) {
              const additionalFloors =
                Number(
                  moveRequest.delivery_details.delivery_addresses[0].floor_no
                ) - 2;
              const flight_fee = additionalFloors * 75;
              setTotalMovePrice((total) => total + flight_fee);
            }
          }
        }
      }

      if (
        moveRequest.delivery_details.packagaing_required === true &&
        moveRequest.delivery_details.packaging !== null
      ) {
        setTotalMovePrice(
          (total) =>
            total +
            Number(moveRequest.delivery_details.packaging.packaging_payment)
        );
      }

      if (
        moveRequest.apartment !== null ||
        moveRequest.storage !== null ||
        moveRequest.combo_apartment_storage !== null ||
        moveRequest.combo_home_storage !== null
      ) {
        const charges = chargesForPickupLocation(moveRequest);
        setTotalMovePrice((total) => total + Number(charges));
      }
      const bulky_fee = await calculateBulkyFee();
      setTotalMovePrice((total) => total + bulky_fee);
      setWeMoveCommission((commission) => commission + 0.129 * price);
    }

    if (Number(moveDistance) > 100 && Number(moveDistance) <= 250) {
      const price_per_cubic_feet = 5.12;
      const price = totalCubicFeet * price_per_cubic_feet * 1.1;
      setPriceOfItemsOnly((total) => total + price);
      setTotalMovePrice((thisPrice) => thisPrice + price);
      if (
        moveRequest.delivery_details.delivery_addresses[0]
          .delivery_location_type !== "Home"
      ) {
        if (
          moveRequest.delivery_details.delivery_addresses[0]
            .is_elevator_accessible
        ) {
          // const price_after_delivery_side_elevator = price + 75;
          setTotalMovePrice((total) => total + 75);
        } else {
          if (
            moveRequest.delivery_details.delivery_addresses[0].stiars_present
          ) {
            if (
              moveRequest.delivery_details.delivery_addresses[0].floor_no > 2
            ) {
              const additionalFloors =
                Number(
                  moveRequest.delivery_details.delivery_addresses[0].floor_no
                ) - 2;
              const flight_fee = additionalFloors * 75;
              setTotalMovePrice((total) => total + flight_fee);
            }
          }
        }
      }
      if (
        moveRequest.delivery_details.packagaing_required === true &&
        moveRequest.delivery_details.packaging !== null
      ) {
        setTotalMovePrice(
          (total) =>
            total +
            Number(moveRequest.delivery_details.packaging.packaging_payment)
        );
      }

      if (
        moveRequest.apartment !== null ||
        moveRequest.storage !== null ||
        moveRequest.combo_apartment_storage !== null ||
        moveRequest.combo_home_storage !== null
      ) {
        const charges = chargesForPickupLocation(moveRequest);
        setTotalMovePrice((total) => total + Number(charges));
      }
      const bulky_fee = await calculateBulkyFee();
      setTotalMovePrice((total) => total + bulky_fee);
      setWeMoveCommission((commission) => commission + 0.279 * price);
    }

    if (Number(moveDistance) > 250 && Number(moveDistance) <= 500) {
      const price_per_cubic_feet = 6;
      const price = totalCubicFeet * price_per_cubic_feet * 1.1;
      setPriceOfItemsOnly((total) => total + price);
      setTotalMovePrice((thisPrice) => thisPrice + price);
      if (
        moveRequest.delivery_details.delivery_addresses[0]
          .delivery_location_type !== "Home"
      ) {
        if (
          moveRequest.delivery_details.delivery_addresses[0]
            .is_elevator_accessible
        ) {
          // const price_after_delivery_side_elevator = price + 75;
          setTotalMovePrice((total) => total + 75);
        } else {
          if (
            moveRequest.delivery_details.delivery_addresses[0].stiars_present
          ) {
            if (
              moveRequest.delivery_details.delivery_addresses[0].floor_no > 2
            ) {
              const additionalFloors =
                Number(
                  moveRequest.delivery_details.delivery_addresses[0].floor_no
                ) - 2;
              const flight_fee = additionalFloors * 75;
              setTotalMovePrice((total) => total + flight_fee);
            }
          }
        }
      }
      if (
        moveRequest.delivery_details.packagaing_required === true &&
        moveRequest.delivery_details.packaging !== null
      ) {
        setTotalMovePrice(
          (total) =>
            total +
            Number(moveRequest.delivery_details.packaging.packaging_payment)
        );
      }

      if (
        moveRequest.apartment !== null ||
        moveRequest.storage !== null ||
        moveRequest.combo_apartment_storage !== null ||
        moveRequest.combo_home_storage !== null
      ) {
        const charges = chargesForPickupLocation(moveRequest);
        setTotalMovePrice((total) => total + Number(charges));
      }
      const bulky_fee = await calculateBulkyFee();
      setTotalMovePrice((total) => total + bulky_fee);
      setWeMoveCommission((commission) => commission + 0.279 * price);
    }

    if (Number(moveDistance) > 500 && Number(moveDistance) <= 1500) {
      console.log('here in bracket')
      const price_per_cubic_feet = 6.75;
      const price = totalCubicFeet * price_per_cubic_feet * 1.1;
      console.log(price,'-->>priceeeee')
      setPriceOfItemsOnly((total) => total + price);
      setTotalMovePrice((thisPrice) => thisPrice + price);
      if (
        moveRequest.delivery_details.delivery_addresses[0]
          .delivery_location_type !== "Home"
      ) {
        if (
          moveRequest.delivery_details.delivery_addresses[0]
            .is_elevator_accessible
        ) {
          // const price_after_delivery_side_elevator = price + 75;
          setTotalMovePrice((total) => total + 75);
          
        } else {
          if (
            moveRequest.delivery_details.delivery_addresses[0].stiars_present
          ) {
            if (
              moveRequest.delivery_details.delivery_addresses[0].floor_no > 2
            ) {
              const additionalFloors =
                Number(
                  moveRequest.delivery_details.delivery_addresses[0].floor_no
                ) - 2;
              const flight_fee = additionalFloors * 75;
              setTotalMovePrice((total) => total + flight_fee);
            }
          }
        }
      }

      if (
        moveRequest.delivery_details.packagaing_required === true &&
        moveRequest.delivery_details.packaging !== null
      ) {
        setTotalMovePrice(
          (total) =>
            total +
            Number(moveRequest.delivery_details.packaging.packaging_payment)
        );
      }
      if (
        moveRequest.apartment !== null ||
        moveRequest.storage !== null ||
        moveRequest.combo_apartment_storage !== null ||
        moveRequest.combo_home_storage !== null
      ) {
        const charges = chargesForPickupLocation(moveRequest);
        setTotalMovePrice((total) => total + Number(charges));
      }
      const bulky_fee = await calculateBulkyFee();
      setTotalMovePrice((total) => total + bulky_fee);
      console.log(totalMovePrice,'---->>TOTAL PRICE')
      setWeMoveCommission((commission) => commission + 0.279 * price);
      console.log(weMoveCommision,'---->>TOTAL COMMISSION----')
    }

    if (Number(moveDistance) > 1500 && Number(moveDistance) <= 2250) {
      const price_per_cubic_feet = 8;
      const price = totalCubicFeet * price_per_cubic_feet * 1.1;
      setPriceOfItemsOnly((total) => total + price);
      setTotalMovePrice((thisPrice) => thisPrice + price);
      if (
        moveRequest.delivery_details.delivery_addresses[0]
          .delivery_location_type !== "Home"
      ) {
        if (
          moveRequest.delivery_details.delivery_addresses[0]
            .is_elevator_accessible
        ) {
          // const price_after_delivery_side_elevator = price + 75;
          setTotalMovePrice((total) => total + 75);
        } else {
          if (
            moveRequest.delivery_details.delivery_addresses[0].stiars_present
          ) {
            if (
              moveRequest.delivery_details.delivery_addresses[0].floor_no > 2
            ) {
              const additionalFloors =
                Number(
                  moveRequest.delivery_details.delivery_addresses[0].floor_no
                ) - 2;
              const flight_fee = additionalFloors * 75;
              setTotalMovePrice((total) => total + flight_fee);
            }
          }
        }
      }
      if (
        moveRequest.delivery_details.packagaing_required === true &&
        moveRequest.delivery_details.packaging !== null
      ) {
        setTotalMovePrice(
          (total) =>
            total +
            Number(moveRequest.delivery_details.packaging.packaging_payment)
        );
      }
      if (
        moveRequest.apartment !== null ||
        moveRequest.storage !== null ||
        moveRequest.combo_apartment_storage !== null ||
        moveRequest.combo_home_storage !== null
      ) {
        const charges = chargesForPickupLocation(moveRequest);
        setTotalMovePrice((total) => total + Number(charges));
      }
      const bulky_fee = await calculateBulkyFee();
      setTotalMovePrice((total) => total + bulky_fee);
      setWeMoveCommission((commission) => commission + 0.279 * price);
    }

    if (Number(moveDistance) > 2250 && Number(moveDistance) <= 3500) {
      const price_per_cubic_feet = 9;
      const price = totalCubicFeet * price_per_cubic_feet * 1.1;
      setPriceOfItemsOnly((total) => total + price);
      setTotalMovePrice((thisPrice) => thisPrice + price);
      if (
        moveRequest.delivery_details.delivery_addresses[0]
          .delivery_location_type !== "Home"
      ) {
        if (
          moveRequest.delivery_details.delivery_addresses[0]
            .is_elevator_accessible
        ) {
          // const price_after_delivery_side_elevator = price + 75;
          setTotalMovePrice((total) => total + 75);
        } else {
          if (
            moveRequest.delivery_details.delivery_addresses[0].stiars_present
          ) {
            if (
              moveRequest.delivery_details.delivery_addresses[0].floor_no > 2
            ) {
              const additionalFloors =
                Number(
                  moveRequest.delivery_details.delivery_addresses[0].floor_no
                ) - 2;
              const flight_fee = additionalFloors * 75;
              setTotalMovePrice((total) => total + flight_fee);
            }
          }
        }
      }
      if (
        moveRequest.delivery_details.packagaing_required === true &&
        moveRequest.delivery_details.packaging !== null
      ) {
        setTotalMovePrice(
          (total) =>
            total +
            Number(moveRequest.delivery_details.packaging.packaging_payment)
        );
      }

      if (
        moveRequest.apartment !== null ||
        moveRequest.storage !== null ||
        moveRequest.combo_apartment_storage !== null ||
        moveRequest.combo_home_storage !== null
      ) {
        const charges = chargesForPickupLocation(moveRequest);
        setTotalMovePrice((total) => total + Number(charges));
      }
      const bulky_fee = await calculateBulkyFee();
      setTotalMovePrice((total) => total + bulky_fee);
      setWeMoveCommission((commission) => commission + 0.279 * price);
    }
  };
  const [showDistanceModal, setShowDistanceModal] = useState(false);
  const [tempDistance, setTempDistance] = useState<any>("");
  const handleDistanceChange = (e: any) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, "");
    const maxLength = 4;

    if (inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength);
    }

    setTempDistance(inputValue);
  };
  const handleDistanceSubmit = () => {
    if (tempDistance > 3500) {
      alert("Please enter a distance less than 3500 miles.");
    } else {
      setMoveDistance(Number(tempDistance));
      setShowDistanceModal(false);
    }
  };
  useEffect(()=>{
    console.log(totalMovePrice,'---changeddd')
    if(totalMovePrice!==0){
      console.log('NowSavingggg')
      updateMove({
        move_payment: Math.ceil(Number(totalMovePrice)),
        price_of_items: Number(Math.ceil(priceOfItemsOnly)),
        move_distance: Math.ceil(Number(moveDistance)),
      })
    }
  },[totalMovePrice])

  const [isFinalizeFlowModal, setIsFinalizeFlowModal] = useState(false)
  return (
    <>
      <Modal
        open={showDistanceModal}
        onClose={() => {
          setShowDistanceModal(true);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 3,
            alignItems: "center",
            justifyContent: "center",
            border: `4px solid ${theme.palette.primary.main}`,
          }}
        >
          <Typography fontSize={14} mb={1}>
            We could not determine the distance between your pickup and delivery
            locations, please enter an approximate distance.
          </Typography>
          <Typography fontSize={12} fontWeight={600} mb={2}>
            Note: This approximation will be used in calculating your quote.
          </Typography>
          <Stack width={150} spacing={1}>
            <TextField
              variant="outlined"
              type="text"
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              label="Distance"
              sx={{ width: 150 }}
              size="small"
              required
              value={tempDistance || ""}
              onChange={handleDistanceChange}
            />
            <Button
              disabled={tempDistance === ""}
              onClick={handleDistanceSubmit}
              sx={{ width: 120, alignSelf: "center" }}
              variant="contained"
            >
              Submit
            </Button>
          </Stack>
        </Box>
      </Modal>
      <NavBar moveRequestId={moveRequestId} />
      <Grid container>
        {isInputAlertModal && (
          <InputAlertModal
            isInputAlertModal={isInputAlertModal}
            setIsInputAlertModal={setIsInputAlertModal}
          />
        )}

        <Grid item xs={12} md={5} p={2} marginX={"auto"}>
        {process.env.REACT_APP_TENANT==='wemove'||process.env.REACT_APP_TENANT==='turbotenant'&&(
        <Stack mt={3} alignSelf={'center'} justifyContent={'center'} alignItems={'center'}>
            <ContactSupport/>
          </Stack>
          )}
          <ProgressBar value={80} />
          <Stack
            mt={2}
            justifyContent={"space-between"}
            direction={"row"}
            alignItems={"center"}
          >
            <Button
              onClick={() => {
                navigate(-1);
              }}
              sx={{ marginTop: 1, marginLeft: 1 }}
            >
              <ArrowBackIcon
                sx={{ color: theme.palette.primary.main }}
                fontSize="large"
              />
            </Button>
            <Box alignItems={"center"} alignSelf={"center"}>
              <Button
                onClick={() => {
                  setIsInputAlertModal(true);
                }}
              >
                <InfoIcon
                  sx={{
                    color: theme.palette.primary.main,
                    fontSize: 30,
                    "&:hover": {
                      color: "#000000",
                      fontSize: 30,
                    },
                  }}
                />
              </Button>
            </Box>
          </Stack>
          {showSuccessMsg && (
            <Box
              p={2}
              mt={2}
              sx={{ borderRadius: 2, backgroundColor: "#46A577" }}
            >
              <Typography fontSize={16} fontWeight={600} color={"#FFFFFF"}>
                Inventory for new images added successfully. Click on Proceed
                button to continue.
              </Typography>
            </Box>
          )}

          {/* {roomDetailsArray && roomDetailsArray.length > 0 ? (
            roomDetailsArray.map((room: any, index: any) => (
              <Box key={room.id}>
                <Box display={"flex"} justifyContent={"space-between"} mt={3}>
                  <Typography>
                    Inventory from{" "}
                    <Typography fontWeight={600}>{room.title}</Typography>
                  </Typography>
                  <Button
                    size="medium"
                    onClick={() => {
                      handleAddItemModal(room.id);
                    }}
                    sx={addButton}
                  >
                    Add Item
                  </Button>
                </Box>
                <TableContainer
                  sx={{
                    mt: 1,
                  }}
                >
                  <Table sx={{ maxWidth: "100vh" }} aria-label="item table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Item Name</StyledTableCell>
                        <StyledTableCell align="center">Width</StyledTableCell>
                        <StyledTableCell align="center">Length</StyledTableCell>
                        <StyledTableCell align="center">Height</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {room.items.map((item: any, index: any) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell component="th" scope="row">
                            <Stack
                              alignItems={"center"}
                              spacing={1}
                              direction={"row"}
                            >
                              <IconButton
                                size="small"
                                onClick={() => handleSubtract(room.id, index)}
                                sx={iconButtonStyle}
                              >
                                <Subtract sx={{ width: 15 }} />
                              </IconButton>
                              <Typography>{item.item_name}</Typography>

                              <IconButton
                                size="small"
                                sx={iconButtonStyle}
                                onClick={() =>
                                  handleAddItemModal(room.id, item, index)
                                }
                              >
                                <EditIcon sx={{ width: 11 }} />
                              </IconButton>
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.item_width}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.item_height}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.item_length}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ))
          ) : (
            <Typography textAlign={"center"} fontWeight={600} color={"#949494"}>
              NO ITEMS
            </Typography>
          )} */}
          {roomDetailsArray && roomDetailsArray.length > 0 ? (
            roomDetailsArray.map((room: any, roomIndex: any) => (
              <Box key={room.id}>
                {room.room_images && room.room_images.length > 0 ? (
                  room.room_images.map((image: any, imageIndex: any) => (
                    <Box marginTop={2} key={image.id}>
                      <Stack
                        alignItems={"flex-end"}
                        justifyContent={"space-between"}
                        direction={"row"}
                      >
                        <Stack spacing={0.5}>
                          <Typography>Inventory from:</Typography>
                          <Typography fontWeight={600}>{room.title}</Typography>
                          <Typography fontWeight={600}>
                            Image: {imageIndex + 1}
                          </Typography>
                          <Button
                            size="medium"
                            onClick={() => {
                              handleAddItemModal(room.id, image.id, null);
                            }}
                            sx={addButton}
                          >
                            Add Item
                          </Button>
                        </Stack>
                        <img
                          src={image.image_url}
                          style={{ borderRadius: 4, border: "1px solid black" }}
                          width={120}
                          height={120}
                          alt=""
                        />
                      </Stack>
                      <TableContainer
                        sx={{
                          mt: 1,
                        }}
                      >
                        <Table
                          sx={{ maxWidth: "100vh" }}
                          aria-label="item table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Item Name</StyledTableCell>
                              <StyledTableCell align="center">
                                Width
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Length
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Height
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {image.items && image.items.length > 0 ? (
                              image.items.map((item: any, itemIndex: any) => (
                                <TableRow
                                  key={itemIndex}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <StyledTableCell component="th" scope="row">
                                    <Stack
                                      alignItems={"center"}
                                      spacing={1}
                                      direction={"row"}
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          handleSubtract(
                                            room.id,
                                            image.id,
                                            itemIndex
                                          )
                                        }
                                        sx={iconButtonStyle}
                                      >
                                        <Subtract sx={{ width: 15 }} />
                                      </IconButton>
                                      <Typography>{item.item_name}</Typography>
                                      <IconButton
                                        size="small"
                                        sx={iconButtonStyle}
                                        onClick={() =>
                                          handleAddItemModal(
                                            room.id,
                                            image.id,
                                            item,
                                            itemIndex
                                          )
                                        }
                                      >
                                        <EditIcon sx={{ width: 11 }} />
                                      </IconButton>
                                    </Stack>
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {item.item_width}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {item.item_height}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {item.item_length}
                                  </StyledTableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <StyledTableCell colSpan={4} align="center">
                                  No items
                                </StyledTableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Divider />
                    </Box>
                  ))
                ) : (
                  <Typography
                    textAlign={"center"}
                    fontWeight={600}
                    color={"#949494"}
                  >
                    No images
                  </Typography>
                )}
              </Box>
            ))
          ) : (
            <Typography textAlign={"center"} fontWeight={600} color={"#949494"}>
              NO ITEMS
            </Typography>
          )}

          <Box
            mt={2}
            sx={{
              p: 2,
              backgroundColor: "#5A7BFC14",
              border: "1px solid #5A7BFC59",
              borderRadius: "8px",
            }}
          >
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography fontSize={14} fontWeight={550} color={"#3C3C3C"}>
                Total Items:
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={550}
                color={'#000000'}
              >
                {itemsCount}
              </Typography>
            </Stack>
          </Box>
          {moveRequest?.location_type !== "storage-only" && (
            <Box
              mt={2}
              sx={{
                p: 2,
                backgroundColor: "#5A7BFC14",
                border: "1px solid #5A7BFC59",
                borderRadius: "8px",
              }}
            >
              <Typography
                fontSize={13}
                fontWeight={600}
                color={'#000000'}
              >
                Tap + or - to add or remove boxes.
              </Typography>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography fontSize={14} fontWeight={550} color={"#3C3C3C"}>
                  Total boxes anticipated for boxable items:
                </Typography>
                <Stack alignItems={"center"} spacing={1} direction={"row"}>
                  <Typography
                    fontSize={14}
                    fontWeight={550}
                    color={'#000000'}
                  >
                    {numberOfBoxexRequired}
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={() =>
                      setNumberOfBoxexRequired(
                        (prevNumber: any) => prevNumber + 1
                      )
                    }
                    sx={iconButtonStyle}
                  >
                    <AddCircleIcon sx={{ width: 15 }} />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (numberOfBoxexRequired !== 0) {
                        setNumberOfBoxexRequired(
                          (prevNumber: any) => prevNumber - 1
                        );
                      }
                    }}
                    sx={iconButtonStyle}
                  >
                    <RemoveCircleIcon sx={{ width: 15 }} />
                  </IconButton>
                </Stack>
              </Stack>
            </Box>
          )}
          <Box display={"flex"} justifyContent={"end"} mt={3}>
            <Button
              onClick={() => {
                handleAddMoreImages();
              }}
              sx={blueButton}
            >
              Add More Images +
            </Button>
          </Box>
          <Box display={"flex"} justifyContent={"end"} mt={3}>
            <Button
              onClick={() => {
                updateThisMoveRequest();
              }}
              size="medium"
              sx={blueButton}
            >
              Proceed
            </Button>
          </Box>

          {/* {isAddItemModal && (
            <AddItemModal
              isAddItemModal={isAddItemModal}
              setIsAddItemModal={setIsAddItemModal}
              editItemInfo={editItemInfo}
              setEditItemInfo={setEditItemInfo}
              roomId={roomId}
              itemIndex={itemIndex}
              roomDetailsArray={roomDetailsArray}
              setRoomDetailsArray={setRoomDetailsArray}
            />
          )} */}
          {isAddItemModal && (
            <AddItemModal
              isAddItemModal={isAddItemModal}
              setIsAddItemModal={setIsAddItemModal}
              editItemInfo={editItemInfo}
              setEditItemInfo={setEditItemInfo}
              roomId={roomId}
              imageId={imageId}
              itemIndex={itemIndex}
              roomDetailsArray={roomDetailsArray}
              setRoomDetailsArray={setRoomDetailsArray}
            />
          )}

          {isPackaginServiceModal && (
            <PackagingServiceModal
              thisMoveRequestId={thisMoveRequestId}
              moveRequestId={canonicalId}
              isPackaginServiceModal={isPackaginServiceModal}
              setIsPackaginServiceModal={setIsPackaginServiceModal}
            />
          )}
          {isFinalizeFlowModal&&(
            <FinalizeFlowModal
            isFinalizeFlowModal={isFinalizeFlowModal}
            setIsFinalizeFlowModal={setIsFinalizeFlowModal}
            setIsPackaginServiceModal={setIsPackaginServiceModal}
            moveRequestId={canonicalId}
            />
          )}
          {isUserInfoModal&&(
            <UserInfoModal
            isUserInfoModal={UserInfoModal}
            setIsUserInfoModal={setIsUserInfoModal}
            thisMoveRequestId={moveRequest.id}
            moveRequestId={moveRequestId}
            setIsFinalizeFlowModal={setIsFinalizeFlowModal}
            setIsPageLoading={setIsPageLoading}
            setIsPackaginServiceModal={setIsPackaginServiceModal}
            moveRequest={moveRequest}/>
          )}

          {uploadMoreImagesModalVisile && (
            <UploadMoreImagesModal
              uploadMoreImagesModalVisile={uploadMoreImagesModalVisile}
              setUploadMoreImagesModalVisile={setUploadMoreImagesModalVisile}
              actionType={actionType}
              moveRequest={moveRequest}
            />
          )}

          {isPageLoading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isPageLoading}
              onClick={() => {
                setIsPageLoading(true);
              }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ItemsInfo;

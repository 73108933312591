import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getMoveRequestById } from "../../redux/actions/move-request";
import { unwrapResult } from "@reduxjs/toolkit";
import { io } from "socket.io-client";
import CelebrationIcon from "@mui/icons-material/Celebration";
import Arrow from "../../assets/images/Arrow.png";
import ReactGA from 'react-ga4'
import ContactSupport from "../../components/contactSupport";
import VideoPopup from "../../components/modals/videoPopup";



const VideoGuidelines = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const colorBadge = {
    marginTop: 2,
    fontSize: 14,
    fontWeight: 800,
    backgroundColor: `${theme.palette.primary.main}`,
    color: "#FFFFFF",
    width: "80px",
    height: "34px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { moveRequestId } = useParams();
  const [thisMoveRequestId, setThisMoveRequestId] = useState(null);

  // const [jwtToken, setJwtToken] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(false);
  const thisUrl = process.env.REACT_APP_API_URL || "";
  const [shouldRedirect, setShouldRedirect] = useState(false);
  useEffect(() => {
    localStorage.removeItem("sessionId");
    localStorage.removeItem("flowStartedOn");
  }, []);
  useEffect(() => {
    const socket = io(thisUrl);

    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    socket.on("connect_error", (error) => {
      console.error("WebSocket connection error:", error);
    });

    socket.on("notifyToRedirect", (requestData: any) => {
      setShouldRedirect(requestData);
    });

    return () => {
      socket.disconnect();
    };
  }, []);
  useEffect(() => {
    if (shouldRedirect === true) {
      navigate(`/AddressInfo/${thisMoveRequestId}`);
    }
  }, [shouldRedirect]);
  const sessionId = moveRequestId;
  const fetchMoveRequestById = (moveRequestId: any) => {
    dispatch<any>(getMoveRequestById(moveRequestId))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        console.log("move req in guidelines=>", thisMoveRequest.canonical_id);
        setThisMoveRequestId(thisMoveRequest.canonical_id);
        const token = localStorage.getItem("jwtToken");
        // setJwtToken(token || "");
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (moveRequestId) {
      fetchMoveRequestById(moveRequestId);
    }
  }, [moveRequestId]);

  useEffect(() => {
    setIsPageLoading(true);
    setTimeout(() => {
      setIsPageLoading(false);
    }, 1300);
  }, []);
  const url = process.env.REACT_APP_URL;
  useEffect(() => {
    console.log(localURL, "--LOCAL");
  }, [url]);
  // const prodURL = `https://production.wemoveai.com/upload-inventory/${thisMoveRequestId}?tokenvalue=${jwtToken}&sessionId=${sessionId}`;
  // const urlForScanning = `${url}/upload-inventory/${thisMoveRequestId}?tokenvalue=${jwtToken}&sessionId=${sessionId}`;
  // const localURL = `http://localhost:3000/upload-inventory/${thisMoveRequestId}?tokenvalue=${jwtToken}&sessionId=${sessionId}`;

  const urlForScanning = `${url}/upload-inventory/${moveRequestId}?&sessionId=${sessionId}`;
  const localURL = `http://localhost:3000/upload-inventory/${moveRequestId}?&sessionId=${sessionId}`;

  if(url==='https://stagingapi.wemoveai.com/'){
    ReactGA.send({
      hitType: 'pageview',
      page:"/inventory-guidelines/:moveRequestId",
      title:"Video GuideLines Staging"
    })
  }  
   
  if(url==='https://productionapi.wemoveai.com/'){
    ReactGA.send({
      hitType: 'pageview',
      page:"/inventory-guidelines/:moveRequestId",
      title:"Video GuideLines Production"
    })
  }  

  if(url==='https://turbotenantapi.wemoveai.com/'){
    ReactGA.send({
      hitType: 'pageview',
      page:"/inventory-guidelines/:moveRequestId",
      title:"Video GuideLines Turbotenant"
    })
  }  

  if(url==='https://soldapi.wemoveai.com/'){
    ReactGA.send({
      hitType: 'pageview',
      page:"/inventory-guidelines/:moveRequestId",
      title:"Video GuideLines Sold"
    })
  } 
const [open, setOpen] = useState(true)
  return (
    <>
      <Grid
        container
        sx={{
          padding: isMobile ? 2 : 5,
        }}
      >
        <Grid item xs={12}>
          {process.env.REACT_APP_TENANT==='wemove'||process.env.REACT_APP_TENANT==='turbotenant'&&(
        <Stack mt={3} alignSelf={'center'} justifyContent={'center'} alignItems={'center'}>
            <ContactSupport/>
          </Stack>
          )}
          <Box display={"flex"} justifyContent={"center"}>
            <CelebrationIcon
              sx={{
                width: 50,
                height: 50,
              }}
            />
          </Box>

          <Typography
            mt={1}
            fontSize={28}
            fontWeight={400}
            letterSpacing={1.5}
            textAlign={"center"}
            color={theme.palette.primary.main}
            // fontFamily={"times-regular"}
          >
            Congratulations
          </Typography>
        </Grid>
        {open&&(
          <VideoPopup
          open={open}
          setOpen={setOpen}
          />
        )}

        <Grid item xs={8} marginX={"auto"}>
          <Typography fontSize={14} mt={1} mb={3} textAlign={"center"}>
            You made it to the inventory capture section. The next steps are
            straightforward. You will be prompted to take images of each room,
            garage, patio, etc.
            <span style={{ color: `${theme.palette.primary.main}`, fontWeight:'bold' }}>
              {" "}
              The total process should take less than 5 minutes.
            </span>
          </Typography>

          <Divider variant="middle" />
        </Grid>
      </Grid>
      <Grid item xs={12} p={4}>
        <Stack alignItems={'center'} alignSelf={'center'}>
      <Button onClick={()=>{
        setOpen(true)
      }}  sx={{
        alignSelf: 'center',
        backgroundColor: theme.palette.primary.main, // Normal background color
        "&:hover": {
          backgroundColor: "#000000", // Hover background color
          color: "#FDFCFD", // Hover text color
        },
      }}variant="contained">
            Click here for instructions
          </Button>
        </Stack>
        <Typography sx={colorBadge}>Step 1</Typography>
        <Typography fontSize={16} marginTop={2}>
          Please follow these steps to itemize your inventory by room:
          <ol style={{ listStyleType: "upper-roman" }}>
            <li>Name each room on the next page.</li>
            <li>
              Take a picture of each room, capturing as much as possible in one
              image. If necessary, take additional pictures to cover the entire
              room, ensuring minimal overlap. You can also use the panoramic
              option to capture more of the room in fewer images.
            </li>
            <li>
              Once your inventory is generated, you will have the option to edit
              it.
            </li>
          </ol>
        </Typography>
      </Grid>

      <Grid
        container
        p={4}
        sx={{
          background: "#dcd6f4",
          border: '1px solid black'
        }}
      >
        <Grid item xs={12} sm={6} marginY={"auto"}>
          <Typography sx={colorBadge}>Step 2</Typography>
          <Typography fontSize={16} marginTop={2}>
            To get started, scan the QR code with your phone to take photos of
            each room. If you're already on your phone, you can proceed
            directly. You can switch between your phone and computer anytime.
            Just follow the steps provided.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} marginX={"auto"}>
          <Box marginTop={2} display={"flex"} justifyContent={"center"}>
            <QRCode size={200} value={urlForScanning} viewBox={`0 0 256 256`} />
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12} p={4}>
        <Box display={"flex"} justifyContent={"center"}>
          <img src={Arrow} width={180} alt="" />
        </Box>

        <Box mt={2} display={"flex"} justifyContent={"center"}>
          <Button
            variant="contained"
            onClick={() => {
              navigate(
                // `/upload-inventory/${thisMoveRequestId}?tokenvalue=${jwtToken}`
                `/upload-inventory/${thisMoveRequestId}`
              );
            }}
            sx={{
              background: theme.palette.primary.main,
              paddingTop: "12px",
              paddingBottom: "12px",
              borderRadius: 10,
              fontFamily: "monospace",
              fontWeight: 550,
              letterSpacing: 1.5,
              width: 450,
              "&:hover": {
          backgroundColor: "#000000", // Hover background color
          color: "#FDFCFD", // Hover text color
        },
            }}
          >
            Give permission & Continue
          </Button>
        </Box>
      </Grid>

      {isPageLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isPageLoading}
          onClick={() => {
            setIsPageLoading(true);
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
};

export default VideoGuidelines;

import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormGroup,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import logoCube from "../../assets/logo/Group 15.png";
import logoWeMove from "../../assets/logo/WEMOVE.png";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { consumerLogin, registerConsumer } from "../../redux/actions/consumer";
import { unwrapResult } from "@reduxjs/toolkit";
import TurboTenantLogo from "../../assets/logo/turbotenant_logo.png";
import { attachUserWithMoveRequest, getMoveRequestById } from "../../redux/actions/move-request";
import MoveBeesLogo from "../../assets/logo/MoveBees-Logo-VR-Yellow.jpg"
import KruppLogo from "../../assets/logo/Krupp.png"
import SummitMovingLogo from "../../assets/logo/SummitMoving.png"
const UserVerification = () => {
  const { moveRequestId } = useParams();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isMobileOrTab = useMediaQuery(theme.breakpoints.down("md"));
  const currentTenant = process.env.REACT_APP_TENANT || "";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");

  const [phoneNoError, setPhoneNoError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [formError, setFormError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const validateName = (name: any) => /^[a-zA-Z ]+$/.test(name);
  const [thisMoveRequestId,setThisMoveRequestId] = useState(null)
  const validatePhoneNumber = (phoneNumber: any) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phoneNumber);
  };
  const fetchMoveRequestById = (moveRequestId: any) => {
    dispatch<any>(getMoveRequestById(moveRequestId))
      .then(unwrapResult)
      .then((move: any) => {
        setThisMoveRequestId(move.id);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const validateEmail = (email: any) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email || !emailRegex.test(email)) {
      setEmailError("Invalid Email");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };

  const handlePhoneNoChange = (e: any) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, "");
    const maxLength = 10;

    if (inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength);
    }

    setPhoneNo(inputValue);

    if (inputValue.length !== maxLength) {
      setPhoneNoError("Please enter a valid 10-digit phone number");
    } else {
      setPhoneNoError("");
    }
  };

  const handleEmailChange = (e: any) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (!validateEmail(newEmail)) {
      setEmailError("Invalid Email");
    } else {
      setEmailError("");
    }
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    setFormError("");
    if (!validateName(firstName) || !validateName(lastName)) {
      setFormError("Name should not be empty");
    } else if (!validateEmail(email)) {
      setFormError("Invalid Email");
    } else if (!validatePhoneNumber(phoneNo)) {
      setFormError("Invalid Phone Number");
    } else {
      setIsLoading(true);
      setIsPageLoading(true)
      const body = {
        email: email,
        first_name: firstName,
        last_name: lastName,
        phone_number: "+1" + phoneNo,
      };

      dispatch<any>(registerConsumer(body))
        .then(unwrapResult)
        .then((res: any) => {
          localStorage.setItem("consumerPhoneNo", res.phone_number);
          const body = {
            phone_number: res.phone_number,
          };
          dispatch<any>(consumerLogin(body))
            .then(unwrapResult)
            .then((res: any) => {
              if (res) {
                const user = res;
                const params ={
                  moveRequestId: thisMoveRequestId,
                  userId: user.id
                }
                dispatch<any>(attachUserWithMoveRequest(params))
                .then(unwrapResult)
                .then((res:any)=>{             
                setTimeout(() => {
                  // if(localStorage.getItem("user-origin")==="user-verification" ){
                    // localStorage.setItem("user-origin","login")
                  // } else {
                    localStorage.setItem("user-origin","user-verification")
                  // }
                  setIsLoading(false);
                  setIsPageLoading(false);
                  navigate("/otpVerificaion", {
                    state: { user: user, role: "consumer" },
                  });
                }, 1300);
              })
              }
            })
            .catch((err: any) => {
              setIsLoading(false);
              setTimeout(() => {
                setIsPageLoading(false);
              }, 1300);
              setPhoneNoError(err);
            });
          // navigate("/otpVerificaion", {
          //   state: { user: res, role: "consumer" },
          // });
        })
        .catch((err: any) => {
          setIsLoading(false);
          setTimeout(() => {
            setIsPageLoading(false);
          }, 1300);
          setFormError(
            "Account with this email or phone number already exists"
          );
        });
    }
  };

  useEffect(() => {
    fetchMoveRequestById(moveRequestId)
    setIsPageLoading(true);
    setTimeout(() => {
      setIsPageLoading(false);
    }, 1300);
  }, []);

  return (
    <>
      <Grid container p={3} height={isMobileOrTab ? "100%" : "101vh"}>
        <Grid
          item
          xs={12}
          sm={8}
          md={5.7}
          marginX={"auto"}
          sx={{
            background: "#FFFFFF",
            borderTop: `4px solid ${theme.palette.primary.main}`,
            borderRadius: 4,
          }}
        >
          <Box p={isMobile ? 2 : 5}>
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              direction={"row"}
              mt={3}
            >
              { currentTenant === "wemove" || currentTenant ==='sold'? (
          <>
            <img src={logoCube} width={30} height={30} alt="" />
            <img src={logoWeMove} width={110} height={18} alt="" />
          </>
        ) : currentTenant === "turbotenant" ? (
            <img src={TurboTenantLogo} width={150} height={44} alt="" />
        ) : currentTenant === 'movebees'? (<img src={MoveBeesLogo} width={150} height={44} alt="" /> ) 
        : currentTenant === 'summitmoving'? (<img src={SummitMovingLogo} width={150} height={44} alt="" /> ) 
        : currentTenant === 'krupp'? (<img src={KruppLogo} width={150} height={44} alt="" /> ) 
        :(<></>)
        }
            </Stack>

            <Typography mt={5} fontSize="16px" textAlign={"center"} fontWeight={600}>
              Great News, this is the last step before you are provided with your guaranteed quote.  Please remember that your information will never be shared or sold.
            </Typography>

            {formError && (
              <Alert sx={{ mt: 1 }} severity="error">
                {formError}
              </Alert>
            )}

            <form onSubmit={handleFormSubmit} autoComplete="off">
              <FormGroup>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    spacing={1}
                  >
                    <TextField
                      label="First Name"
                      variant="outlined"
                      type="text"
                      fullWidth
                      margin="normal"
                      size="small"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />

                    <TextField
                      label="Last Name"
                      variant="outlined"
                      type="text"
                      value={lastName}
                      fullWidth
                      margin="normal"
                      size="small"
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                  </Stack>

                  <TextField
                    label="Email Address"
                    variant="outlined"
                    type="email"
                    value={email}
                    fullWidth
                    margin="normal"
                    size="small"
                    onChange={handleEmailChange}
                    error={Boolean(emailError)}
                    helperText={emailError}
                  />

                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    type="text"
                    value={phoneNo}
                    fullWidth
                    margin="normal"
                    size="small"
                    onChange={handlePhoneNoChange}
                    error={Boolean(phoneNoError)}
                    helperText={phoneNoError}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+1</InputAdornment>
                      ),
                    }}
                  />

                  <Typography
                    mt={2}
                    fontSize={12}
                    fontWeight={600}
                    color={"#3C3C3C"}
                  >
                    To verify that you are a human and not a bot, we request your email address. This allows us to send you a verification code if needed.
                  </Typography>

                  <Stack>
                    {isLoading ? (
                      <Box>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          marginTop: 2,
                          height: 50,
                          backgroundColor: theme.palette.primary.main,
                          color: "#FFFFFF",
                          border: "2px solid #CCCCFF",
                          fontSize: 12,
                          fontWeight: 600,
                          borderRadius: 3,
                          width: 200,
                          alignSelf: "flex-end",
                        }}
                      >
                        Generate My Estimate
                      </Button>
                    )}
                  </Stack>
                </FormControl>
              </FormGroup>
            </form>
            {isPageLoading && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isPageLoading}
                onClick={() => {
                  setIsPageLoading(true);
                }}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default UserVerification;

import {
  Box,
  Button,
  ButtonBase,
  Divider,
  Grid,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import WeMoveHeader from "../../components/header/weMoveHeader";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect, useState } from "react";
import RediractionMessageModal from "../../components/modals/rediractionMessageModal";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getMoveRequestById,
  updateMoveRequestById,
} from "../../redux/actions/move-request";
import { unwrapResult } from "@reduxjs/toolkit";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import WidgetsIcon from "@mui/icons-material/Widgets";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import Person4OutlinedIcon from "@mui/icons-material/Person4Outlined";
import ReactGA from 'react-ga4';
import ContactSupport from "../../components/contactSupport";

const howerButton = {
  width: "100%",
  display: "block",
  "&:hover .hover-text": {
    color: "#5858E0",
  },
  "&:hover .hover-background": {
    backgroundColor: "#FFFFFF",
    boxShadow: "10px 10px 5px -2px rgba(88, 88, 224, 0.3)",
  },
  "&:hover .icon-background": {
    backgroundColor: "#5858E0",
  },
  "&:hover .icon": {
    color: "#FFFFFF",
  },
};

const MoveSuccess = () => {
   
  const [url, setUrl] = useState(process.env.REACT_APP_URL || "")

  if(url==='https://stagingapi.wemoveai.com/'){
    ReactGA.send({
      hitType: 'pageview',
      page:"/move-success/:moveRequestId",
      title:"MoveSuccess Staging"
    })
  }  
   
  if(url==='https://productionapi.wemoveai.com/'){
    ReactGA.send({
      hitType: 'pageview',
      page:"/move-success/:moveRequestId",
      title:"MoveSuccess Production"
    })
  }  

  if(url==='https://turbotenantapi.wemoveai.com/'){
    ReactGA.send({
      hitType: 'pageview',
      page:"/move-success/:moveRequestId",
      title:"MoveSuccess Turbotenant"
    })
  }  

  if(url==='https://soldapi.wemoveai.com/'){
    ReactGA.send({
      hitType: 'pageview',
      page:"/move-success/:moveRequestId",
      title:"MoveSuccess Sold"
    })
  }  

  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const { moveRequestId } = useParams();
  const [moveRequest, setMoveRequest] = useState<any>(null);

  const [link, setLink] = useState("");
  const [isRedirectMessageModal, setIsRedirectMessageModal] = useState(false);

  const handleRediractionModal = (url: any) => {
    setLink(url);
    setIsRedirectMessageModal(true);
  };
  const [count, setCount] = useState(0);
  const fetchMoveRequestById = (moveRequestId: any) => {
    dispatch<any>(getMoveRequestById(moveRequestId))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        setMoveRequest(thisMoveRequest);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (moveRequestId) {
      fetchMoveRequestById(moveRequestId);
    }
  }, [moveRequestId]);
  useEffect(() => {
    if (moveRequest !== null && count === 0) {
      setCount((counter) => counter + 1);
      updateThisMoveRequest(
        {
          contract_signed: true,
        },
        moveRequest.id
      );
    }
  }, [moveRequest]);
  const handleNavigation = () => {
    setFinishModal(true);
    const newWindow = window.open(
      `/ConsumerDashboard/${moveRequest.user.canonical_id}`,
      "_blank"
    );
    if (newWindow) {
      // The new window/tab was successfully created, now close the current window
      window.close();
    } else {
      // The new window/tab couldn't be created (possibly due to popup blockers)
      alert("Please allow popups for this website");
    }
  };
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [finishModal, setFinishModal] = useState(false);
  const updateThisMoveRequest = (body: any, moveReqId: number) => {
    setIsPageLoading(true);
    const params = {
      id: moveReqId,
      ...body,
    };
    dispatch<any>(updateMoveRequestById(params))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        console.log("contract marked");
        setTimeout(() => {
          setIsPageLoading(false);
        }, 1300);
      })
      .catch((err: any) => {
        console.log(err);
        setTimeout(() => {
          setIsPageLoading(false);
        }, 1300);
      });
  };
  return (
    <>
      <WeMoveHeader />
      <Modal
        open={finishModal}
        onClose={() => {
          setFinishModal(true);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 3,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            color={"#5858E0"}
            fontSize={15}
            fontWeight={600}
            fontStyle={"italic"}
          >
            You have been redirected to your dashboard in a new window. Please
            close this window. Thanks.
          </Typography>
        </Box>
      </Modal>
      <Grid container>
        <Grid item xs={12} sm={8} md={5} marginX={"auto"}>
      
          <Box
            p={4}
            display={"flex"}
            justifyContent={"center"}
            sx={{ background: "#5858E0" }}
          >
            <Stack alignItems={"center"}>
              <CheckCircleIcon
                fontSize="large"
                sx={{ color: "#FFFFFF", width: 120, height: 120 }}
              />
              <Typography
                fontSize={14}
                textAlign={"center"}
                fontWeight={600}
                mt={2}
                color={"#FFFFFF"}
              >
                Your move has been confirmed. We will reach out to you soon.
              </Typography>
              <Typography
                fontSize={14}
                textAlign={"center"}
                fontWeight={400}
                mt={2}
                color={"#FFFFFF"}
              >
                View order summary
              </Typography>
            </Stack>
          </Box>

          <Box p={1}>
            <Typography fontSize={12} fontWeight={400} mt={2} mb={2}>
              Thanks so much for being a trusted WeMove customer. We look
              forward to making your move and transition as seamless as
              possible. Please keep an eye out for moving updates and tips via
              email and SMS. If you need anything along your journey, please
              don’t hesitate to reach out. You will be receiving additional
              information along the way.
            </Typography>

            <Box display={"flex"}>
              <Typography fontSize={14} fontWeight={550} mt={2}>
                CARRIER NAME:
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={550}
                mt={2}
                ml={1}
                color={"#5858E0"}
              >
                WEMOVE CARRIER NETWORK
              </Typography>
            </Box>

            <Box display={"flex"}>
              <Typography fontSize={14} mb={2} fontWeight={550}>
                CONTACT:
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={550}
                ml={1}
                color={"#5858E0"}
              >
                302-353-4106
              </Typography>
            </Box>

            <Divider variant="middle" />

            <Typography fontSize={12} fontWeight={400} mt={2} mb={2}>
              If you will be needing optional auto transport, additional
              insurance, or packing materials, please continue. If you don’t,
              feel free to close this window. You can always log into your
              account from the login page on WeMove. We thank you again for
              being part of the trusted WeMove family. We are here to help in
              every way we can.
            </Typography>
          </Box>

          {/* <Box
            p={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              background: "#5858E0",
              borderRadius: 4,
            }}
          >
            <Stack alignItems={"center"}>
              <Typography
                fontSize={12}
                fontWeight={550}
                mt={2}
                mb={2}
                textAlign={"center"}
                color={"#FFFFFF"}
              >
                Would you like to transport a vehicle, motorcycle, or other?
              </Typography>
              <Button
                onClick={() => {
                  handleRediractionModal("https://wemove.ai/auto-transport/");
                }}
              >
                <Card sx={{ maxWidth: 150, borderRadius: 3, flexShrink: 0 }}>
                  <CardContent>
                    <Stack alignItems={"center"} spacing={1}>
                      <img src={CarIcon} width={80} height={50} alt="" />

                      <Typography fontSize={12} fontWeight={400}>
                        Transport Car
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
              </Button>

              <Typography
                fontSize={12}
                fontWeight={550}
                mt={2}
                mb={2}
                textAlign={"center"}
                color={"#FFFFFF"}
              >
                Will you be needing to purchase any moving supplies from boxes,
                bins, to tape?
              </Typography>
              <Button
                onClick={() => {
                  handleRediractionModal(
                    "https://shareasale.com/r.cfm?b=883129&u=4219278&m=66601&urllink=&afftrack="
                  );
                }}
              >
                <Card sx={{ maxWidth: 150, borderRadius: 3, flexShrink: 0 }}>
                  <CardContent>
                    <Stack alignItems={"center"} spacing={1}>
                      <img src={BoxIcon} width={80} height={50} alt="" />

                      <Typography fontSize={12} fontWeight={400}>
                        Need Supplies?
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
              </Button>

              <Typography
                fontSize={12}
                fontWeight={550}
                mt={2}
                mb={2}
                textAlign={"center"}
                color={"#FFFFFF"}
              >
                Would you like to add additional specialty insurance to your
                move ranging from a single item to full coverage?
              </Typography>
              <Button
                onClick={() => {
                  handleRediractionModal(
                    "https://www.movinginsurance.com/landing.asp?ID=G58NPM596Q"
                  );
                }}
              >
                <Card sx={{ maxWidth: 150, borderRadius: 3, flexShrink: 0 }}>
                  <CardContent>
                    <Stack alignItems={"center"} spacing={1}>
                      <img src={ShieldIcon} width={80} height={50} alt="" />

                      <Typography fontSize={12} fontWeight={400}>
                        Need Insurance?
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
              </Button>
            </Stack>
          </Box> */}

          <Stack
            direction={isMobile ? "column" : "row"}
            spacing={1}
            mt={2}
            alignItems={"center"}
          >
            <Grid item xs={10} sm={6}>
              <ButtonBase
                onClick={() =>
                  handleRediractionModal("https://wemove.ai/auto-transport/")
                }
                sx={howerButton}
              >
                <Box
                  p={1}
                  className="hover-background"
                  sx={{
                    background: "#5858E0",
                    borderRadius: 4,
                    transition: "background-color 0.3s",
                    height: 250,
                  }}
                >
                  <Stack alignItems={"center"}>
                    <Typography
                      fontSize={14}
                      fontWeight={550}
                      mt={2}
                      mb={2}
                      textAlign={"center"}
                      color={"#FFFFFF"}
                      className="hover-text"
                      sx={{ transition: "color 0.3s" }}
                    >
                      Would you like to transport a vehicle, motorcycle, or
                      other?
                    </Typography>

                    <Box
                      p={2}
                      className="icon-background"
                      sx={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: 14,
                        transition: "background-color 0.3s",
                      }}
                    >
                      <DirectionsCarIcon
                        className="icon"
                        sx={{
                          color: "#5858E0",
                          width: 40,
                          height: 40,
                          transition: "filter 0.3s",
                          filter: "invert(0)",
                        }}
                      />
                    </Box>

                    <Typography
                      fontSize={14}
                      fontWeight={400}
                      textAlign={"center"}
                      mt={2}
                      mb={2}
                      color={"#FFFFFF"}
                      className="hover-text"
                      sx={{ transition: "color 0.3s" }}
                    >
                      Transport Car?
                    </Typography>
                  </Stack>
                </Box>
              </ButtonBase>
            </Grid>

            <Grid item xs={10} sm={6}>
              <ButtonBase
                onClick={() => {
                  handleRediractionModal(
                    "https://shareasale.com/r.cfm?b=883129&u=4219278&m=66601&urllink=&afftrack="
                  );
                }}
                sx={howerButton}
              >
                <Box
                  p={1}
                  className="hover-background"
                  sx={{
                    background: "#5858E0",
                    borderRadius: 4,
                    transition: "background-color 0.3s",
                    height: 250,
                  }}
                >
                  <Stack alignItems={"center"}>
                    <Typography
                      fontSize={14}
                      fontWeight={550}
                      mt={2}
                      mb={2}
                      textAlign={"center"}
                      color={"#FFFFFF"}
                      className="hover-text"
                      sx={{ transition: "color 0.3s" }}
                    >
                      Will you be needing to purchase any moving supplies from
                      boxes, bins, to tape?
                    </Typography>

                    <Box
                      p={2}
                      className="icon-background"
                      sx={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: 14,
                        transition: "background-color 0.3s",
                      }}
                    >
                      <WidgetsIcon
                        className="icon"
                        sx={{
                          color: "#5858E0",
                          width: 40,
                          height: 40,
                          transition: "filter 0.3s",
                          filter: "invert(0)",
                        }}
                      />
                    </Box>

                    <Typography
                      fontSize={14}
                      fontWeight={400}
                      textAlign={"center"}
                      mt={2}
                      mb={2}
                      color={"#FFFFFF"}
                      className="hover-text"
                      sx={{ transition: "color 0.3s" }}
                    >
                      Need Supplies?
                    </Typography>
                  </Stack>
                </Box>
              </ButtonBase>
            </Grid>
          </Stack>

          <Stack
            direction={isMobile ? "column" : "row"}
            spacing={1}
            mt={2}
            alignItems={"center"}
          >
            <Grid item xs={10} sm={6}>
              <ButtonBase
                onClick={() =>
                  handleRediractionModal(
                    "https://www.movinginsurance.com/WeMove.asp"
                  )
                }
                sx={howerButton}
              >
                <Box
                  p={1}
                  className="hover-background"
                  sx={{
                    background: "#5858E0",
                    borderRadius: 4,
                    transition: "background-color 0.3s",
                    height: 250,
                  }}
                >
                  <Stack alignItems={"center"}>
                    <Typography
                      fontSize={14}
                      fontWeight={550}
                      mt={2}
                      mb={2}
                      textAlign={"center"}
                      color={"#FFFFFF"}
                      className="hover-text"
                      sx={{ transition: "color 0.3s" }}
                    >
                      Would you like to add additional specialty insurance to
                      your move ranging from a single item to full coverage?
                    </Typography>

                    <Box
                      p={2}
                      className="icon-background"
                      sx={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: 14,
                        transition: "background-color 0.3s",
                      }}
                    >
                      <GppGoodOutlinedIcon
                        className="icon"
                        sx={{
                          color: "#5858E0",
                          width: 40,
                          height: 40,
                          transition: "filter 0.3s",
                          filter: "invert(0)",
                        }}
                      />
                    </Box>

                    <Typography
                      fontSize={14}
                      fontWeight={400}
                      textAlign={"center"}
                      mt={2}
                      mb={2}
                      color={"#FFFFFF"}
                      className="hover-text"
                      sx={{ transition: "color 0.3s" }}
                    >
                      Need Insurance?
                    </Typography>
                  </Stack>
                </Box>
              </ButtonBase>
            </Grid>

            <Grid item xs={10} sm={6}>
              <ButtonBase
                onClick={() =>
                  handleRediractionModal(
                    'https://wemoveai.moveeasy.com/'
                  )
                }
                sx={howerButton}
              >
                <Box
                  p={1}
                  className="hover-background"
                  sx={{
                    background: "#5858E0",
                    borderRadius: 4,
                    transition: "background-color 0.3s",
                    height: 250,
                  }}
                >
                  <Stack alignItems={"center"}>
                    <Typography
                      fontSize={14}
                      fontWeight={550}
                      mt={2}
                      mb={2}
                      textAlign={"center"}
                      color={"#FFFFFF"}
                      className="hover-text"
                      sx={{ transition: "color 0.3s" }}
                    >
                      Would you like a free, dedicated concierge to assist you
                      with all of your pre and post moving needs? Change your
                      internet. Set up your services and more.
                    </Typography>

                    <Box
                      p={2}
                      className="icon-background"
                      sx={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: 14,
                        transition: "background-color 0.3s",
                      }}
                    >
                      <Person4OutlinedIcon
                        className="icon"
                        sx={{
                          color: "#5858E0",
                          width: 40,
                          height: 40,
                          transition: "filter 0.3s",
                          filter: "invert(0)",
                        }}
                      />
                    </Box>

                    <Typography
                      fontSize={14}
                      fontWeight={400}
                      textAlign={"center"}
                      mt={2}
                      mb={2}
                      color={"#FFFFFF"}
                      className="hover-text"
                      sx={{ transition: "color 0.3s" }}
                    >
                      Need a Concierge?
                    </Typography>
                  </Stack>
                </Box>
              </ButtonBase>
            </Grid>
          </Stack>

          <Stack p={3} alignItems="center">
            <Button
              onClick={() => {
                // navigate(`/ConsumerDashboard/${moveRequest.user.id}`);
                handleNavigation();
              }}
              sx={{
                width: 180,
                height: 45,
                backgroundColor: "#5858E0 !important",
                color: "#FFFFFF",
                fontSize: 12,
                fontWeight: 550,
                border: "1px solid #6552FF",
              }}
            >
              Continue
            </Button>
          </Stack>
          <Stack m={3} alignSelf={'center'} justifyContent={'center'} alignItems={'center'}>
          <ContactSupport/>
          </Stack>
        </Grid>
      </Grid>

      {isRedirectMessageModal && (
        <RediractionMessageModal
          isRedirectMessageModal={isRedirectMessageModal}
          setIsRedirectMessageModal={setIsRedirectMessageModal}
          link={link}
        />
      )}
    </>
  );
};

export default MoveSuccess;
